const labels = {
  All: {
    'status: all': 'Kaikki',
    'status: open': 'Avata',
    'status: pending': 'Vireillä',
    'status: closed': 'Suljettu',
    Location: 'Sijainti',
    Subject: 'Aihe',
    Description: 'Yksityiskohtainen pyyntösi',
    'Ref: description field label': 'Kuvaus',
    'Ref: Title': 'Otsikko',
    phone_number: 'Puhelinnumerosi',
    request_category: 'Pyynnön luokka',
    Attachments: 'Liitetiedostot',
    'request service': 'Pyydä palvelua',
    'New service request': 'Luo toinen pyyntö',
    'Go to home': 'Palaa takaisin',
    'Back to this service request': 'Takaisin tähän palvelupyyntöön',
    'Back to service requests': 'Takaisin palvelupyyntöihin',
    Feedback: 'Palaute',
    'Ref: Request category': 'Kategoria',
    Cleaning: 'Puhdistus',
    'Laundry services': 'Pesulapalvelut',
    'Grounds maintenance': 'Ympäristöjen ylläpito',
    'Soft services': 'Siivous/ravintola palvelut',
    'Hard services': 'Tekniset palvelut',
    'Pest control services': 'Tuholaistorjuntapalvelut',
    'Waste management': 'Jätehuolto',
    'Asset Lifecycle Services': 'Asset Lifecycle Services',
    'There are no updates for this request': 'Tälle pyynnölle ei ole päivityksiä',
    'Transportation Services': 'Kuljetuspalvelu',
    'Ref: you pronoun': '(sinä)',
    'Ref: SR raised for': 'Tätä henkilöä tällä pyynnöllä',
    'Ref: Select a location': 'Valitse sijainti',
    'Ref: Your request': 'Sinun pyyntösi',
    'Ref: Summary': 'Yhteenveto',
    'Ref: site': 'Paikka',
  },
  Comment: {
    'Ref: Page title': 'Päivitä US',
    'Ref: please update us with details':
      'Lisätietoja meille? Kommentti? Mitä enemmän tiedämme pyynnöstäsi, sitä paremmin voimme auttaa sinua.',
  },
  Confirm: {
    'Ref: Page title': 'Vahvista pyyntösi',
    'Ref: next': 'Lähetä',
    summary: 'Yhteenveto',
    'Ref: Save as my preferred location': 'Tallenna mieluisina sijaintina',
  },
  Describe: {
    'Ref: Page title': 'Kuvaile pyyntöäsi',
    'Ref: Required fields: header': 'Anna lisätietoja',
    'Ref: Required fields': 'Seuraavat tiedot on toimitettava yksityiskohtien sisällä:',
    'Ref: Required field: title': 'Otsikko vaaditaan.',
    'Ref: Required field: description': 'Kuvaus vaaditaan.',
    'Ref: Required field: phone': 'Puhelinnumero vaaditaan.',
    'Ref: Required field: category': 'Luokka vaaditaan.',
    'Ref: Invalid field: category': 'Edellyttäen, että luokka on virheellinen.',
    'Ref: Request template field label': 'Suositut pyynnöt',
    'Ref: Request template field placeholder': 'Tyhjä sivu',
    your_request: 'Sinun pyyntösi',
    'Ref: Description message': 'Mitä yksityiskohtaisempi olet, sitä paremmin voimme tukea sinua.',
    phone_number: 'Puhelinnumerosi (käytä ammatillista puhelinta, jos sinulla on)',
    'Ref: Affected person': 'Tätä henkilöä tällä pyynnöllä',
    'Ref: No affected person results': 'Kukaan henkilö vastaa pyydettyjä kriteerejä',
    'Ref: Notification title': 'Olet vaihtanut sijaintia',
    'Ref: Notification info': 'Olet nyt {site name}',
    'Ref: Add photos': 'Lisää kuvia',
    'Ref: Your location': 'Sijaintisi',
    'Ref: limit field: title': 'otsikon tulee olla alle {ref} merkkiä',
    'Ref: limit field: description': 'kuvauksen tulee olla alle {ref} merkkiä',
  },
  Details: {
    'Ref: Page title': 'Pyydä yksityiskohtia',
    Details: 'Yksityiskohdat',
    Updates: 'Päivitykset',
    'Update us': 'Päivitä US',
    'Ref: timeline': 'Aikajana',
    'Ref: attachments': 'Liitetiedot',
    'Ref: location': 'Sijainti',
    'Ref: createdOn': 'Luotu',
    'Ref: reference': 'Viite',
    'Feedback was submitted': 'Palaute lähetetty',
    'Ref: sent': 'Lähetetty',
    'Ref: received': 'vastaanotettu',
    'Ref: inProgress': 'Meneillään',
    'Ref: completed': 'Valmis',
    'Ref: conversation': 'Keskustelu',
    'Ref: Priority': 'Prioriteetti',
    'Ref: Target start date': 'Tavoitteen aloituspäivä',
    'Ref: Target completion date': 'Tavoitteen valmistumispäivä',
    'Ref: Priority fields placeholder message': 'Se asuu, kun tukipalvelu on validoitu',
  },
  Failed: {
    'Ref: Body':
      'Tapahtui virhe ja palvelupyyntöäsi ei luotu. Jos tämä virhe jatkuu, ota yhteyttä tukipalvelu.',
    'Ref: Tabtitle': 'Palvelupyyntö ei luotu',
  },
  Home: { 'Ref: Page title': 'Pyynnöt' },
  RequestFeedback: {
    'Ref: Page title': 'Palaute',
    'Ref: Feedback headline': 'Ole hyvä ja jaa palautteesi',
    'Ref: Feedback title': 'Palaute SR # {Ref}',
    'Ref: Question: general sentiment':
      'Kuinka tyytyväinen olet, miten Sodexo käsitteli palvelupyyntösi?',
    'Ref: Question: answer quality': 'Miten arvioisit saadun palvelun laadun?',
    'Ref: Question: staff interaction': 'Miten arvioisit vuorovaikutusta henkilökuntamme kanssa?',
    'Ref: Question: efficiency': 'Miten arvioisit tehokkuuttamme?',
    'Ref: Question: open': 'Kommentti',
    'Ref: Open question placeholder': 'Mitä teimme hyvin? Mistä voimme parantaa?',
    no_comment: 'Ei kommenttia',
  },
  FeedbackSuccess: {
    'Ref: Body':
      'Kiitos, että sinulla on aikaa jättää kommentti, palaute on tärkeää, jotta voimme parantaa kokemustasi.',
  },
  Locate: {
    'Ref: Page title': 'Valitse sijainti',
    'Ref: Top bar title': 'Etsi pyyntösi',
    'Ref: Select this location': 'Valitse tämä sijainti',
    drill: 'selaa',
    'Ref: Scanner button': 'Sijainti QR: llä? Skannaa se',
    'Ref: Scanner header': 'Skannaa sijaintisi QR',
    'Sorry, this location could not be found.': 'Anteeksi, tätä sijaintia ei löytynyt.',
    'Close scanner': 'Sulje skanneri',
    'Ref: Switch site component intro sentence': 'Pyydät palvelua sivustolla {site name}.',
    'Ref: search location by keywords': 'Etsi sijainti',
    'Ref: Enter Location name': 'Anna sijainnin nimi',
    'Ref: Enter manual location': 'Anna sijainti manuaalisesti',
    'Ref: Location name': 'Paikannimi',
    'Ref: No results': 'Emme löytäneet sijaintiasi. Voit halutessasi syöttää sen manuaalisesti.',
    'Ref: Up one level': 'Yksi taso ylöspäin',
    'Ref: Navigate': 'Navigoi',
    'Ref: Select': 'Valita',
    'Ref: Selected Location': 'Valittu sijainti',
    'Ref: Preferred Location': 'Haluttu sijainti',
    'Ref: Building': 'Rakennus',
    'Ref: Floor': 'Kerros',
    'Ref: Aisle': 'Käytävä',
    'Ref: Room': 'Huone',
    'Ref: Notification title': 'Olet vaihtanut sijaintia',
    'Ref: Notification info': 'Olet nyt {site name}',
    'Ref: Scanner error header': 'Skannausvirhe',
    'Ref: Scan QR Code': 'Skannaa QR -koodi',
    'Ref: Error body': 'Tämä QR -koodi ei ole kelvollinen koodi',
    'Ref: Loading Locations Warning':
      'Odota, kun valmistelemme kokemusta ensimmäisestä pyynnöstä tällä sivustolla.',
    'Ref: Search Result is limited to:':
      'Hakutulos on rajoitettu {limit}. Tarkista hakukriteerisi.',
    'Ref: Open the location search field': 'Avaa Sijaint -hakukenttä',
  },
  Success: {
    'Ref: Body':
      'Palvelupyyntösi toimitettiin ja käsitellään. Se näkyy tässä hakemuksessa pian. Saat ilmoituksen, kun se on ratkaistu. Sillä välin voit seurata sen tilaa ja lisätä kommentteja tämän sovelluksen kautta.',
    'Ref: Tabtitle': 'Luotu palvelupyyntö',
    'Ref: Download app title': 'Yksinkertaista palvelupyyntösi',
    'Ref: Download app subtitle': 'Lataa sovelluksemme ja paranna kokemustasi',
    'Ref: Download app button': 'Lataa sovellus',
  },
  Tutorials: {
    'Ref: Tutorial: title': 'Tämä on työpaikan tukikeskuksen',
    'Ref: Tutorial: tabname': 'Pyynnöt',
    'Ref: Tutorial: body':
      'Olemme täällä auttamassa sinua. Kirjaudu sisään yksi pyyntö merkintää kohti.',
    "What's new: title": 'Etsi sijaintisi helposti',
    "What's new: body":
      'Voit nyt skannata sijainnin QR: n, porautua sijaintitasolla tai valita vain älykkäitä ehdotuksia.',
  },
  Widget: {
    'Ref: Service request': 'Huolto pyyntö',
    'Ref: Placeholder body': 'Ei palvelupyyntöä hetkeksi',
    'Ref: New request button': 'Uusi pyyntö',
  },
  QrLandingPage: {
    'Ref: Notification title': 'Olet vaihtanut sijaintia',
    'Ref: Notification info': 'Olet nyt {site name}',
    'Ref: Welcome': 'Tervetuloa! {wave_emoji}',
    'Ref: Create service request text': 'Huomasitko jotain, josta haluat ilmoittaa?',
    'Create a service request': 'Luo palvelupyyntö',
    'Ref: Share feedback': 'Jaa palautetta',
    'Ref: Share text': 'Haluatko jakaa jotain kanssamme?',
    'Go to home': 'Palaa takaisin',
    'Ref: Error body':
      'Skannaamasi QR-koodi ei enää toimi. Yritä myöhemmin uudelleen tai ota yhteyttä tukeen.',
  },
  QrLandingPageError: {
    'Ref: Error title': 'Virhe on tapahtunut',
    'Ref: Error body':
      'Skannaamasi QR-koodi ei enää toimi. Yritä myöhemmin uudelleen tai ota yhteyttä tukeen.',
    'Go to home': 'Palaa takaisin',
  },
  ManualDescribe: {
    'Ref: Page title': 'Kuvaile pyyntösi',
    'Ref: Required fields: header': 'Anna lisätietoja',
    'Ref: Required fields': 'Seuraavat tiedot tulisi toimittaa yksityiskohdat -kentän sisällä:',
    'Ref: Required field: title': 'Vaaditaan yhteenveto.',
    'Ref: Required field: description': 'Kuvaus vaaditaan.',
    'Ref: Required field: category': 'Luokka vaaditaan.',
    'Ref: Required field: buildingText': 'Vaaditaan rakennus.',
    'Ref: Invalid field: category': 'Edellyttäen, että luokka on virheellinen.',
    'Ref: Your request': 'Sinun pyyntösi',
    'Ref: Description message': 'Mitä yksityiskohtaisempi olet, sitä paremmin voimme tukea sinua.',
    'Ref: Do you have a location QR code': 'Onko sinulla sijainti QR -koodia?',
    'Ref: Scan QR Code': 'Skannaa QR -koodi',
    'Ref: Please select category': 'Valitse luokka',
    'Ref: Please select site': 'Valitse sivusto',
    'Ref: building': 'Rakennus',
    'Ref: room': 'Huone',
    'Ref: Scan error': 'Skannausvirhe',
    'Ref: The QR code is not valid': 'QR -koodi ei ole kelvollinen',
    'Ref: Add location with QR Scanner': 'Lisää sijainti QR -skannerilla',
    'Ref: Description': 'Kuvaus',
    'Ref: Forbidden error':
      'Olemme pahoillamme, tämä QR -koodi ei vastaa rekisteröityä sijaintiasi.',
    'Ref: Invalid error':
      'Olemme pahoillamme, näyttää siltä, ​​että tämä QR -koodi ei ole enää kelvollinen.',
  },
};
export default labels;
