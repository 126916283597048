const labels = {
  All: {
    'status: all': '모두',
    'status: open': '열려 있는',
    'status: pending': '보류 중',
    'status: closed': '닫은',
    Location: '위치',
    Subject: '주제',
    Description: '귀하의 상세한 요청',
    'Ref: description field label': '설명',
    'Ref: Title': '제목',
    phone_number: '전화 번호',
    request_category: '요청 카테고리',
    Attachments: '첨부 파일',
    'request service': '서비스 요청',
    'New service request': '다른 요청을 만듭니다',
    'Go to home': '집에 가십시오',
    'Back to this service request': '이 서비스 요청으로 돌아 가기',
    'Back to service requests': '다시 서비스 요청으로',
    Feedback: '피드백',
    'Ref: Request category': '범주',
    Cleaning: '청소',
    'Laundry services': '세탁 서비스',
    'Grounds maintenance': '근거 정비',
    'Soft services': '소프트 서비스',
    'Hard services': '하드 서비스',
    'Pest control services': '해충 방제 서비스',
    'Waste management': '폐기물 관리',
    'Asset Lifecycle Services': '자산 라이프 사이클 서비스',
    'There are no updates for this request': '이 요청에 대한 업데이트가 없습니다',
    'Transportation Services': '운송 서비스',
    'Ref: you pronoun': '(너)',
    'Ref: SR raised for': '이 요청에 따라 영향을받는 사람',
    'Ref: Select a location': '위치를 선택하십시오',
    'Ref: Your request': '당신의 요청',
    'Ref: Summary': '요약',
    'Ref: site': '대지',
  },
  Comment: {
    'Ref: Page title': '업데이트를 업데이트하십시오',
    'Ref: please update us with details':
      '우리에게 의사 소통을위한 더 많은 정보가 있습니까? 의견? 우리가 귀하의 요청에 대해 더 많이 알수록 당신을 더 잘 지원할 수 있습니다.',
  },
  Confirm: {
    'Ref: Page title': '귀하의 요청을 확인하십시오',
    'Ref: next': '제출하다',
    summary: '요약',
    'Ref: Save as my preferred location': '내가 선호하는 위치로 저장하십시오',
  },
  Describe: {
    'Ref: Page title': '귀하의 요청을 설명하십시오',
    'Ref: Required fields: header': '추가 정보를 제공하십시오',
    'Ref: Required fields': '다음 정보는 세부 정보 필드에 제공되어야합니다.',
    'Ref: Required field: title': '제목이 필요합니다.',
    'Ref: Required field: description': '설명이 필요합니다.',
    'Ref: Required field: phone': '전화 번호가 필요합니다.',
    'Ref: Required field: category': '카테고리가 필요합니다.',
    'Ref: Invalid field: category': '제공된 카테고리가 유효하지 않습니다.',
    'Ref: Request template field label': '인기있는 요청',
    'Ref: Request template field placeholder': '빈 시트',
    your_request: '당신의 요청',
    'Ref: Description message': '당신이 더 상세할수록 우리는 당신을 더 잘 지원할 수 있습니다.',
    phone_number: '귀하의 전화 번호 (전문 전화가있는 경우 전문 전화를 사용하십시오)',
    'Ref: Affected person': '이 요청에 따라 영향을받는 사람',
    'Ref: No affected person results': '일치하는 사람은 요청 기준을 요청하지 않았습니다',
    'Ref: Notification title': '위치가 변경되었습니다',
    'Ref: Notification info': '당신은 이제 {site name}에 있습니다.',
    'Ref: Add photos': '사진 추가',
    'Ref: Your location': '귀하의 위치',
    'Ref: limit field: title': '제목은 {ref}자 미만이어야 합니다.',
    'Ref: limit field: description': '설명은 {ref}자 미만이어야 합니다.',
  },
  Details: {
    'Ref: Page title': '요청 세부 정보',
    Details: '세부',
    Updates: '업데이트',
    'Update us': '업데이트를 업데이트하십시오',
    'Ref: timeline': '타임 라인',
    'Ref: attachments': '첨부 파일',
    'Ref: location': '위치',
    'Ref: createdOn': '에 만든',
    'Ref: reference': '참조',
    'Feedback was submitted': '피드백 보내기',
    'Ref: sent': '전송된',
    'Ref: received': '받았다',
    'Ref: inProgress': '진행 중',
    'Ref: completed': '완전한',
    'Ref: conversation': '대화',
    'Ref: Priority': '우선 사항',
    'Ref: Target start date': '목표 시작 날짜',
    'Ref: Target completion date': '목표 완료 날짜',
    'Ref: Priority fields placeholder message': '안내 데스크에 의해 검증 된 후에는 채워집니다',
  },
  Failed: {
    'Ref: Body':
      '오류가 발생했고 서비스 요청이 작성되지 않았습니다. 이 오류가 계속되면 헬프 데스크에 문의하십시오.',
    'Ref: Tabtitle': '서비스 요청이 생성하지 못했습니다',
  },
  Home: { 'Ref: Page title': '요청' },
  RequestFeedback: {
    'Ref: Page title': '피드백',
    'Ref: Feedback headline': '의견을 공유하십시오',
    'Ref: Feedback title': 'sr # {ref}에 대한 의견',
    'Ref: Question: general sentiment':
      '전반적으로 Sodexo가 서비스 요청을 처리하는 방법에 대해 얼마나 만족하십니까? ...에',
    'Ref: Question: answer quality': '받은 서비스의 품질을 어떻게 평가 하시겠습니까?',
    'Ref: Question: staff interaction': '직원들과 어떻게 상호 작용을 평가 하시겠습니까?',
    'Ref: Question: efficiency': '어떻게 효율성을 평가 하시겠습니까?',
    'Ref: Question: open': '논평',
    'Ref: Open question placeholder':
      '우리는 무엇을 잘 했습니까? 우리는 어디에서 개선 할 수 있습니까?',
    no_comment: '댓글 없음',
  },
  FeedbackSuccess: {
    'Ref: Body':
      '의견을 남기 위해 시간을내어 주셔서 감사합니다. 귀하의 의견은 귀하의 경험을 향상시키는 데 중요합니다.',
  },
  Locate: {
    'Ref: Page title': '귀하의 요청을 찾습니다',
    'Ref: Top bar title': '귀하의 요청을 찾으십시오',
    'Ref: Select this location': '이 위치를 선택하십시오',
    drill: '검색',
    'Ref: Scanner button': 'QR이있는 위치? 그것을 스캔하십시오',
    'Ref: Scanner header': 'QR을 스캔하십시오',
    'Sorry, this location could not be found.': '죄송합니다.이 위치를 찾을 수 없습니다.',
    'Close scanner': '닫기 스캐너',
    'Ref: Switch site component intro sentence': '사이트 {site name}에 서비스를 요청하고 있습니다.',
    'Ref: search location by keywords': '위치를 검색하십시오',
    'Ref: Enter Location name': '위치 이름을 입력하십시오',
    'Ref: Enter manual location': '위치를 수동으로 입력하십시오',
    'Ref: Location name': '위치 명',
    'Ref: No results': '우리는 당신의 위치를 ​​찾을 수 없습니다. 수동으로 입력 할 수 있습니다.',
    'Ref: Up one level': '최대 1 단계',
    'Ref: Navigate': '탐색',
    'Ref: Select': '고르다',
    'Ref: Selected Location': '선택된 위치',
    'Ref: Preferred Location': '선호 위치',
    'Ref: Building': '건물',
    'Ref: Floor': '바닥',
    'Ref: Aisle': '통로',
    'Ref: Room': '방',
    'Ref: Notification title': '위치가 변경되었습니다',
    'Ref: Notification info': '당신은 이제 {site name}에 있습니다.',
    'Ref: Scanner error header': '스캔 오류',
    'Ref: Scan QR Code': 'QR 코드를 스캔하십시오',
    'Ref: Error body': '이 QR 코드는 유효한 코드가 아닙니다',
    'Ref: Loading Locations Warning':
      '이 사이트에서 첫 번째 요청에 대한 경험을 준비하는 동안 기다려주세요.',
    'Ref: Search Result is limited to:':
      '검색 결과는 {limit}로 제한됩니다. 검색 기준을 개선하십시오.',
    'Ref: Open the location search field': '위치 검색 필드를 엽니 다',
  },
  Success: {
    'Ref: Body':
      '귀하의 서비스 요청이 제출되었고 처리 중입니다. 이 응용 프로그램에서 곧 표시됩니다. 해결 될 때 메모를 받게됩니다. 한편 상태를 따르고이 응용 프로그램을 통해 주석을 추가 할 수 있습니다.',
    'Ref: Tabtitle': '서비스 요청이 생성되었습니다',
    'Ref: Download app title': '서비스 요청을 단순화하세요',
    'Ref: Download app subtitle': '앱을 다운로드하고 경험을 향상하세요',
    'Ref: Download app button': '앱 다운로드',
  },
  Tutorials: {
    'Ref: Tutorial: title': '이것은 당신의 직장 지원 허브입니다',
    'Ref: Tutorial: tabname': '요청',
    'Ref: Tutorial: body':
      '우리는 당신을 돕기 위해 여기 있습니다. 항목 당 하나의 요청을 기록하십시오.',
    "What's new: title": '위치를 쉽게 찾으십시오',
    "What's new: body":
      '이제 위치 QR을 스캔하거나 위치 수준을 드릴 다운하거나 지능형 제안에서 선택할 수 있습니다.',
  },
  Widget: {
    'Ref: Service request': '서비스 요청',
    'Ref: Placeholder body': '순간에 대한 서비스 요청이 없습니다',
    'Ref: New request button': '새로운 요청',
  },
  QrLandingPage: {
    'Ref: Notification title': '위치가 변경되었습니다',
    'Ref: Notification info': '당신은 이제 {site name}에 있습니다.',
    'Ref: Welcome': '환영! {wave_emoji}',
    'Ref: Create service request text': '신고하고 싶은 내용을 발견하셨나요?',
    'Create a service request': '서비스 요청을 만듭니다',
    'Ref: Share feedback': '피드백 공유',
    'Ref: Share text': '우리와 뭔가를 공유하고 싶나요?',
    'Go to home': '집에 가십시오',
    'Ref: Error body':
      '스캔한 QR 코드가 더 이상 작동하지 않습니다. 나중에 다시 시도하거나 지원팀에 문의하세요.',
  },
  QrLandingPageError: {
    'Ref: Error title': '오류가 발생했습니다',
    'Ref: Error body':
      '스캔한 QR 코드가 더 이상 작동하지 않습니다. 나중에 다시 시도하거나 지원팀에 문의하세요.',
    'Go to home': '집에 가십시오',
  },
  ManualDescribe: {
    'Ref: Page title': '귀하의 요청을 설명하십시오',
    'Ref: Required fields: header': '추가 정보를 제공하십시오',
    'Ref: Required fields': '세부 사항 필드 내에 다음 정보가 제공되어야합니다.',
    'Ref: Required field: title': '요약이 필요합니다.',
    'Ref: Required field: description': '설명이 필요합니다.',
    'Ref: Required field: category': '카테고리가 필요합니다.',
    'Ref: Required field: buildingText': '건물이 필요합니다.',
    'Ref: Invalid field: category': '제공된 카테고리는 유효하지 않습니다.',
    'Ref: Your request': '당신의 요청',
    'Ref: Description message': '당신이 더 상세할수록 우리는 당신을 더 잘 지원할 수 있습니다.',
    'Ref: Do you have a location QR code': '위치 QR 코드가 있습니까?',
    'Ref: Scan QR Code': 'QR 코드를 스캔하십시오',
    'Ref: Please select category': '카테고리를 선택하십시오',
    'Ref: Please select site': '사이트를 선택하십시오',
    'Ref: building': '건물',
    'Ref: room': '방',
    'Ref: Scan error': '스캔 오류',
    'Ref: The QR code is not valid': 'QR 코드는 유효하지 않습니다',
    'Ref: Add location with QR Scanner': 'QR 스캐너로 위치를 추가하십시오',
    'Ref: Description': '설명',
    'Ref: Forbidden error': '죄송합니다.이 QR 코드는 등록 된 위치와 일치하지 않습니다.',
    'Ref: Invalid error': '죄송합니다.이 QR 코드가 더 이상 유효하지 않은 것 같습니다.',
  },
};
export default labels;
