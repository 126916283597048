const labels = {
  All: {
    'status: all': 'tất cả',
    'status: open': 'Mở',
    'status: pending': 'Đang chờ xử lý',
    'status: closed': 'Đóng ',
    Location: 'Vị trí',
    Subject: 'Chủ đề',
    Description: 'Yêu cầu chi tiết của bạn',
    'Ref: description field label': 'Sự mô tả',
    'Ref: Title': 'Tiêu đề',
    phone_number: 'Số điện thoại của bạn',
    request_category: 'Danh mục yêu cầu',
    Attachments: 'Tài liệu đính kèm',
    'request service': 'Yêu cầu dịch vụ',
    'New service request': 'Tạo một yêu cầu khác',
    'Go to home': 'Đi về nhà',
    'Back to this service request': 'Quay lại yêu cầu dịch vụ này',
    'Back to service requests': 'Quay lại yêu cầu dịch vụ',
    Feedback: 'Bình luận',
    'Ref: Request category': 'Loại',
    Cleaning: 'Làm sạch',
    'Laundry services': 'Dịch vị giặt là',
    'Grounds maintenance': 'Bảo trì mặt bằng',
    'Soft services': 'Dịch vụ mềm',
    'Hard services': 'Dịch vụ cứng',
    'Pest control services': 'Dịch vụ kiểm soát dịch hại',
    'Waste management': 'Quản lý chất thải',
    'Asset Lifecycle Services': 'Tài sản LifeCycle Services.',
    'There are no updates for this request': 'Không có bản cập nhật cho yêu cầu này',
    'Transportation Services': 'Dịch vụ vận chuyển',
    'Ref: you pronoun': '(bạn)',
    'Ref: SR raised for': 'Người bị ảnh hưởng theo yêu cầu này',
    'Ref: Select a location': 'Chọn một vị trí',
    'Ref: Your request': 'Yêu cầu của bạn',
    'Ref: Summary': 'Bản tóm tắt',
    'Ref: site': 'Địa điểm',
  },
  Comment: {
    'Ref: Page title': 'Cập nhật cho chúng tôi',
    'Ref: please update us with details':
      'Thêm thông tin để liên lạc với chúng tôi? Một lời bình luận? Chúng tôi càng biết nhiều về yêu cầu của bạn, chúng tôi càng có thể hỗ trợ bạn tốt hơn.',
  },
  Confirm: {
    'Ref: Page title': 'Xác nhận yêu cầu của bạn',
    'Ref: next': 'Nộp',
    summary: 'chủ đề',
    'Ref: Save as my preferred location': 'Lưu dưới dạng vị trí ưa thích của tôi',
  },
  Describe: {
    'Ref: Page title': 'mô tả yêu cầu của bạn',
    'Ref: Required fields: header': 'Vui lòng cung cấp thêm thông tin',
    'Ref: Required fields': 'Các thông tin sau cần được cung cấp bên trong trường Chi tiết:',
    'Ref: Required field: title': 'Một chức vụ cần thiết.',
    'Ref: Required field: description': 'một mô tả la cần thiết',
    'Ref: Required field: phone': 'Một số điện thoại là bắt buộc.',
    'Ref: Required field: category': 'Một thể loại là bắt buộc.',
    'Ref: Invalid field: category': 'Cung cấp danh mục không hợp lệ.',
    'Ref: Request template field label': 'Yêu cầu phổ biến',
    'Ref: Request template field placeholder': 'Tờ giấy trắng',
    your_request: 'Yêu cầu của bạn',
    'Ref: Description message': 'Bạn càng chi tiết, chúng tôi càng có thể hỗ trợ bạn tốt hơn.',
    phone_number:
      'Số điện thoại của bạn (vui lòng sử dụng điện thoại chuyên nghiệp của bạn nếu bạn có)',
    'Ref: Affected person': 'Người bị ảnh hưởng theo yêu cầu này',
    'Ref: No affected person results': 'Không ai phù hợp với tiêu chí được yêu cầu',
    'Ref: Notification title': 'Bạn đã thay đổi vị trí',
    'Ref: Notification info': 'Bây giờ bạn đang ở {site name}',
    'Ref: Add photos': 'Thêm ảnh',
    'Ref: Your location': 'Vị trí của bạn',
    'Ref: limit field: title': 'tiêu đề phải ít hơn {ref} ký tự',
    'Ref: limit field: description': 'mô tả phải ít hơn {ref} ký tự',
  },
  Details: {
    'Ref: Page title': 'Yêu cầu chi tiết',
    Details: 'Chi tiết ',
    Updates: 'Cập nhật',
    'Update us': 'Cập nhật cho chúng tôi',
    'Ref: timeline': 'Mốc thời gian',
    'Ref: attachments': 'Đính kèm',
    'Ref: location': 'Địa điểm',
    'Ref: createdOn': 'Được tạo ra',
    'Ref: reference': 'Thẩm quyền giải quyết',
    'Feedback was submitted': 'Phản hồi đã gửi',
    'Ref: sent': 'Gởi',
    'Ref: received': 'Nhận',
    'Ref: inProgress': 'Trong tiến trình',
    'Ref: completed': 'Hoàn thành',
    'Ref: conversation': 'Cuộc hội thoại',
    'Ref: Priority': 'Sự ưu tiên',
    'Ref: Target start date': 'Ngày bắt đầu mục tiêu',
    'Ref: Target completion date': 'Ngày hoàn thành mục tiêu',
    'Ref: Priority fields placeholder message':
      'Nó sẽ được điền một lần được xác nhận bởi bàn trợ giúp',
  },
  Failed: {
    'Ref: Body':
      'Đã xảy ra lỗi và yêu cầu dịch vụ của bạn không được tạo. Nếu lỗi này vẫn còn, xin vui lòng liên hệ với bộ phận trợ giúp của bạn.',
    'Ref: Tabtitle': 'Yêu cầu dịch vụ không thể tạo',
  },
  Home: { 'Ref: Page title': 'Yêu cầu' },
  RequestFeedback: {
    'Ref: Page title': 'Bình luận',
    'Ref: Feedback headline': 'Vui lòng chia sẻ phản hồi của bạn',
    'Ref: Feedback title': 'Phản hồi về SR # {ref}',
    'Ref: Question: general sentiment':
      'Nhìn chung, bạn hài lòng như thế nào với cách Sodexo xử lý yêu cầu dịch vụ của bạn?',
    'Ref: Question: answer quality': 'Bạn đánh giá chất lượng dịch vụ bạn nhận được như thế nào?',
    'Ref: Question: staff interaction':
      'bạn đánh giá tương tác của bạn với nhân viên của chúng tôi như thế nào?',
    'Ref: Question: efficiency': ' bạn đánh giá hiệu quả của chúng tôi như thế nào?',
    'Ref: Question: open': 'Bình luận',
    'Ref: Open question placeholder': 'Chúng ta đã làm gì tốt? Chúng ta có thể cải thiện ở đâu?',
    no_comment: 'Miễn bình luận',
  },
  FeedbackSuccess: {
    'Ref: Body':
      'Cảm ơn bạn đã dành thời gian để bình luận, phản hồi của bạn rất quan trọng để chúng tôi cải thiện trải nghiệm của bạn.',
  },
  Locate: {
    'Ref: Page title': 'Xác định vị trí yêu cầu của bạn',
    'Ref: Top bar title': 'Xác định vị trí yêu cầu của bạn',
    'Ref: Select this location': 'Chọn vị trí này',
    drill: 'Duyệt.',
    'Ref: Scanner button': 'Vị trí với QR? Quét nó',
    'Ref: Scanner header': 'Vui lòng quét vị trí của bạn QR',
    'Sorry, this location could not be found.': 'Xin lỗi, vị trí này không thể được tìm thấy.',
    'Close scanner': 'Đóng máy quét',
    'Ref: Switch site component intro sentence':
      'Bạn đang yêu cầu dịch vụ trên trang web {site name}.',
    'Ref: search location by keywords': 'Tìm kiếm một vị trí',
    'Ref: Enter Location name': 'Nhập tên vị trí.',
    'Ref: Enter manual location': 'Nhập vị trí bằng tay',
    'Ref: Location name': 'Tên địa phương',
    'Ref: No results':
      'Chúng tôi không thể tìm thấy vị trí của bạn. Bạn có thể muốn nhập thủ công.',
    'Ref: Up one level': 'Lên một cấp',
    'Ref: Navigate': 'Điều hướng',
    'Ref: Select': 'Lựa chọn',
    'Ref: Selected Location': 'Vị trí được chọn',
    'Ref: Preferred Location': 'Vị trí ưa thích',
    'Ref: Building': 'Tòa nhà',
    'Ref: Floor': 'Sàn nhà',
    'Ref: Aisle': 'Lối đi',
    'Ref: Room': 'Phòng',
    'Ref: Notification title': 'Bạn đã thay đổi vị trí',
    'Ref: Notification info': 'Bây giờ bạn đang ở {site name}',
    'Ref: Scanner error header': 'Lỗi quét',
    'Ref: Scan QR Code': 'Quét mã QR',
    'Ref: Error body': 'Mã QR này không phải là mã hợp lệ',
    'Ref: Loading Locations Warning':
      'Vui lòng đợi trong khi chúng tôi đang chuẩn bị trải nghiệm của bạn cho một yêu cầu đầu tiên tại trang web này.',
    'Ref: Search Result is limited to:':
      'Kết quả tìm kiếm được giới hạn ở {limit}. Vui lòng tinh chỉnh tiêu chí tìm kiếm của bạn.',
    'Ref: Open the location search field': 'Mở trường tìm kiếm vị trí',
  },
  Success: {
    'Ref: Body':
      'Yêu cầu dịch vụ của bạn đã được gửi và đang được xử lý. Nó sẽ hiển thị trong ứng dụng này trong thời gian ngắn. Bạn sẽ nhận được một ghi chú khi nó được giải quyết. Trong khi đó, bạn có thể theo dõi trạng thái của nó và thêm ý kiến ​​cho nó thông qua ứng dụng này.',
    'Ref: Tabtitle': 'Yêu cầu dịch vụ được tạo',
    'Ref: Download app title': 'Đơn giản hóa các yêu cầu dịch vụ của bạn',
    'Ref: Download app subtitle':
      'Tải xuống ứng dụng của chúng tôi và nâng cao trải nghiệm của bạn',
    'Ref: Download app button': 'Tải xuống ứng dụng',
  },
  Tutorials: {
    'Ref: Tutorial: title': 'Đây là HUB hỗ trợ nơi làm việc của bạn',
    'Ref: Tutorial: tabname': 'Yêu cầu',
    'Ref: Tutorial: body':
      'Chúng tôi đang ở đây để giúp bạn. Vui lòng đăng nhập một yêu cầu cho mỗi mục nhập.',
    "What's new: title": 'Tìm vị trí của bạn một cách dễ dàng',
    "What's new: body":
      'Bây giờ bạn có thể quét một vị trí QR, khoan các mức vị trí xuống hoặc chỉ cần chọn từ các đề xuất thông minh của chúng tôi.',
  },
  Widget: {
    'Ref: Service request': 'Yêu cầu dịch vụ',
    'Ref: Placeholder body': 'Không có yêu cầu dịch vụ cho thời điểm này',
    'Ref: New request button': 'Yêu cầu mới',
  },
  QrLandingPage: {
    'Ref: Notification title': 'Bạn đã thay đổi vị trí',
    'Ref: Notification info': 'Bây giờ bạn đang ở {site name}',
    'Ref: Welcome': 'Chào mừng! {wave_emoji}',
    'Ref: Create service request text': 'Nhận thấy điều gì đó bạn muốn báo cáo?',
    'Create a service request': 'Tạo yêu cầu dịch vụ',
    'Ref: Share feedback': 'Chia sẻ phản hồi',
    'Ref: Share text': 'Bạn có muốn chia sẻ điều gì đó với chúng tôi không?',
    'Go to home': 'Đi về nhà',
    'Ref: Error body':
      'Mã QR bạn đã quét không còn hoạt động. Vui lòng thử lại sau hoặc liên hệ với bộ phận hỗ trợ.',
  },
  QrLandingPageError: {
    'Ref: Error title': 'Một lỗi đã xảy ra',
    'Ref: Error body':
      'Mã QR bạn đã quét không còn hoạt động. Vui lòng thử lại sau hoặc liên hệ với bộ phận hỗ trợ.',
    'Go to home': 'Đi về nhà',
  },
  ManualDescribe: {
    'Ref: Page title': 'Mô tả yêu cầu của bạn',
    'Ref: Required fields: header': 'Vui lòng cung cấp thêm thông tin',
    'Ref: Required fields': 'Các thông tin sau đây phải được cung cấp bên trong trường Chi tiết:',
    'Ref: Required field: title': 'Một bản tóm tắt là bắt buộc.',
    'Ref: Required field: description': 'Một mô tả là bắt buộc.',
    'Ref: Required field: category': 'Một danh mục là bắt buộc.',
    'Ref: Required field: buildingText': 'Một tòa nhà là bắt buộc.',
    'Ref: Invalid field: category': 'Danh mục được cung cấp là không hợp lệ.',
    'Ref: Your request': 'Yêu cầu của bạn',
    'Ref: Description message': 'Bạn càng chi tiết, chúng tôi càng có thể hỗ trợ bạn tốt hơn.',
    'Ref: Do you have a location QR code': 'Bạn có mã QR vị trí không?',
    'Ref: Scan QR Code': 'Quét mã QR',
    'Ref: Please select category': 'Vui lòng chọn danh mục',
    'Ref: Please select site': 'Vui lòng chọn Trang web',
    'Ref: building': 'Tòa nhà',
    'Ref: room': 'Phòng',
    'Ref: Scan error': 'Lỗi quét',
    'Ref: The QR code is not valid': 'Mã QR không hợp lệ',
    'Ref: Add location with QR Scanner': 'Thêm vị trí bằng máy quét QR',
    'Ref: Description': 'Sự mô tả',
    'Ref: Forbidden error':
      'Chúng tôi xin lỗi, mã QR này không khớp với vị trí đã đăng ký của bạn.',
    'Ref: Invalid error': 'Chúng tôi xin lỗi, có vẻ như mã QR này không còn hợp lệ.',
  },
};
export default labels;
