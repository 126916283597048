const labels = {
  All: {
    'status: all': 'Összes',
    'status: open': 'Nyitva',
    'status: pending': 'Függőben levő',
    'status: closed': 'Zárva',
    Location: 'Helyszin',
    Subject: 'Tárgy',
    Description: 'A részletes kérésed',
    'Ref: description field label': 'Leírás',
    'Ref: Title': 'Cím',
    phone_number: 'A telefonszámod',
    request_category: 'Igénylés kategóriája',
    Attachments: 'Mellékletek',
    'request service': 'Kérdőíves szolgáltatás',
    'New service request': 'Hozzon létre egy másik kérést',
    'Go to home': 'Menj haza',
    'Back to this service request': 'Vissza ehhez a szolgáltatáskéréshez',
    'Back to service requests': 'Vissza a szervizkérésekhez',
    Feedback: 'Visszajelzés',
    'Ref: Request category': 'Kategória',
    Cleaning: 'Tisztítás',
    'Laundry services': 'Mosoda',
    'Grounds maintenance': 'Alföldek karbantartása',
    'Soft services': 'Lágy szolgáltatások',
    'Hard services': 'Kemény szolgáltatások',
    'Pest control services': 'Kártevőirtó szolgáltatások',
    'Waste management': 'Hulladékgazdálkodás',
    'Asset Lifecycle Services': 'Asset Lifecycle szolgáltatások',
    'There are no updates for this request': 'Nincsenek frissítések erre a kérésre',
    'Transportation Services': 'Közlekedési szolgáltatás',
    'Ref: you pronoun': '(te)',
    'Ref: SR raised for': 'E kérés által érintett személy',
    'Ref: Select a location': 'Válasszon ki egy helyet',
    'Ref: Your request': 'Kérésed',
    'Ref: Summary': 'Összefoglalás',
    'Ref: site': 'Webhely',
  },
  Comment: {
    'Ref: Page title': 'Tájékoztasson minket',
    'Ref: please update us with details':
      'Még több információja van, amit megosztana velünk? Egy megjegyzés? Minél többet tudunk a kérelméről, annál pontosabb segítséget tudunk nyújtani.',
  },
  Confirm: {
    'Ref: Page title': 'Erősítse meg a kérelmét',
    'Ref: next': 'Beküldés',
    summary: 'Összefoglalás',
    'Ref: Save as my preferred location': 'Mentés, mint a kedvelt helyem',
  },
  Describe: {
    'Ref: Page title': 'Írja le kérését',
    'Ref: Required fields: header': 'Kérjük, adjon meg további információkat',
    'Ref: Required fields': 'A részletek mezőben a következő információkat kell biztosítani:',
    'Ref: Required field: title': 'Cím megadása kötelező.',
    'Ref: Required field: description': 'Leírás megadása kötelező.',
    'Ref: Required field: phone': 'Telefonszám szükséges.',
    'Ref: Required field: category': 'Kategória szükséges.',
    'Ref: Invalid field: category': 'A megadott kategória érvénytelen.',
    'Ref: Request template field label': 'Népszerű kérések',
    'Ref: Request template field placeholder': 'Üres lap',
    your_request: 'A kérésed',
    'Ref: Description message': 'Minél részletesebb vagy, annál jobban tudunk támogatni téged.',
    phone_number: 'A telefonszámát (kérjük, használja a professzionális telefonját, ha van ilyen)',
    'Ref: Affected person': 'E kérés által érintett személy',
    'Ref: No affected person results': 'Senkinek nem egyezik meg a kért kritériumokat',
    'Ref: Notification title': 'Megváltoztatta a helyét',
    'Ref: Notification info': 'Most a {site name}',
    'Ref: Add photos': 'Fényképek hozzáadása',
    'Ref: Your location': 'Ön tartózkodási helyét',
    'Ref: limit field: title': 'A címnek kevesebbnek kell lennie, mint {ref} karakter',
    'Ref: limit field: description': 'a leírásnak kevesebbnek kell lennie, mint {ref} karakter',
  },
  Details: {
    'Ref: Page title': 'Kérjen részleteket',
    Details: 'Részletek',
    Updates: 'Frissítések',
    'Update us': 'Tájékoztasson minket',
    'Ref: timeline': 'Idővonal',
    'Ref: attachments': 'Mellékletek',
    'Ref: location': 'Elhelyezkedés',
    'Ref: createdOn': 'Készült ekkor',
    'Ref: reference': 'Referencia',
    'Feedback was submitted': 'Visszajelzés elküldve',
    'Ref: sent': 'Küldött',
    'Ref: received': 'Beérkezett',
    'Ref: inProgress': 'Folyamatban',
    'Ref: completed': 'Befejezett',
    'Ref: conversation': 'Beszélgetés',
    'Ref: Priority': 'Kiemelten fontos',
    'Ref: Target start date': 'Célkezelési dátum',
    'Ref: Target completion date': 'Terv teljesítés dátuma',
    'Ref: Priority fields placeholder message':
      'A ügyfélszolgálat által érvényesítettek, miután érvényesítik',
  },
  Failed: {
    'Ref: Body':
      'Hiba történt, és a szolgáltatási kérelmet nem hozták létre. Ha ez a hiba továbbra is fennáll, forduljon a ügyfélszolgálat.',
    'Ref: Tabtitle': 'A szolgáltatáskérés nem sikerült létrehozni',
  },
  Home: { 'Ref: Page title': 'Kérések' },
  RequestFeedback: {
    'Ref: Page title': 'Visszajelzés',
    'Ref: Feedback headline': 'Kérjük, ossza meg visszajelzéseit',
    'Ref: Feedback title': 'Visszajelzés az SR # {REF} -ról',
    'Ref: Question: general sentiment':
      'Összességében mennyire elégedett azzal, ahogy a Sodexo kezelte kérvényét?',
    'Ref: Question: answer quality': 'Hogyan értékelné a kapott szolgáltatás minőségét?',
    'Ref: Question: staff interaction': 'Hogyan értékelné a munkatársakkal való együttműködést?',
    'Ref: Question: efficiency': 'Hogyan értékelné a hatékonyságunkat?',
    'Ref: Question: open': 'Megjegyzés',
    'Ref: Open question placeholder': 'Mit csinálunk jól? Hol lehetne még javitani?',
    no_comment: 'No comment',
  },
  FeedbackSuccess: {
    'Ref: Body':
      'Köszönjük, hogy időt szakit a válaszadásra, az Ön visszajelzése fontos számunkra, hogy élményét még jobbá tegyük.',
  },
  Locate: {
    'Ref: Page title': 'Határozza meg a kérést',
    'Ref: Top bar title': 'Keresse meg kérését',
    'Ref: Select this location': 'Válassza ki ezt a helyet',
    drill: 'böngész',
    'Ref: Scanner button': 'A hely QR-vel? Szkennel',
    'Ref: Scanner header': 'Kérjük, szkennelje be a QR helyét',
    'Sorry, this location could not be found.': 'Sajnáljuk, ez a hely nem található.',
    'Close scanner': 'Szoros szkenner',
    'Ref: Switch site component intro sentence': 'Szolgáltatást kér a webhelyen {site name}.',
    'Ref: search location by keywords': 'Keresse meg a helyet',
    'Ref: Enter Location name': 'Adja meg a hely nevét',
    'Ref: Enter manual location': 'Adja meg a helyet manuálisan',
    'Ref: Location name': 'A helyszín neve',
    'Ref: No results': 'Nem találtuk meg a helyét. Lehet, hogy kézzel adja be.',
    'Ref: Up one level': 'Egy szinten',
    'Ref: Navigate': 'Hajózik',
    'Ref: Select': 'Válasszon',
    'Ref: Selected Location': 'Kiválasztott hely',
    'Ref: Preferred Location': 'Előnyben részesített hely',
    'Ref: Building': 'Épület',
    'Ref: Floor': 'Padló',
    'Ref: Aisle': 'Folyosó',
    'Ref: Room': 'Szoba',
    'Ref: Notification title': 'Megváltoztatta a helyét',
    'Ref: Notification info': 'Most a {site name}',
    'Ref: Scanner error header': 'Beolvasási hiba',
    'Ref: Scan QR Code': 'QR kód beolvasása',
    'Ref: Error body': 'Ez a QR -kód nem érvényes kód',
    'Ref: Loading Locations Warning':
      'Kérjük, várjon, amíg előkészítjük tapasztalatait az első kérésre ezen a webhelyen.',
    'Ref: Search Result is limited to:':
      'A keresési eredmény a {limit} -re korlátozódik. Kérjük, finomítsa a keresési kritériumokat.',
    'Ref: Open the location search field': 'Nyissa meg a helykeresési mezőt',
  },
  Success: {
    'Ref: Body':
      'A szolgáltatási kérelmet benyújtották, és feldolgozták. Ez rövid időn belül megmutatja ezt az alkalmazást. Meg kell jegyeznie a megjegyzést, amikor megoldódott. Eközben követheti az állapotát, és megjegyzéseket adhat hozzá ehhez az alkalmazáson keresztül.',
    'Ref: Tabtitle': 'Létrehozott szolgáltatási kérelem',
    'Ref: Download app title': 'Egyszerűsítse szolgáltatási kéréseit',
    'Ref: Download app subtitle': 'Töltsd le alkalmazásunkat, és növeld élményeidet',
    'Ref: Download app button': 'Töltse le az alkalmazást',
  },
  Tutorials: {
    'Ref: Tutorial: title': 'Ez a munkahelyi támogató hub',
    'Ref: Tutorial: tabname': 'Kérések',
    'Ref: Tutorial: body':
      'Azért vagyunk itt, hogy segítsünk. Kérjük, jelentkezzen be egy kérést bejegyzésenként.',
    "What's new: title": 'Keresse meg a helyét könnyedén',
    "What's new: body":
      'Most szkennelhet egy hely QR, fúrja le a helyszínt, vagy csak az intelligens javaslataink közül választhat.',
  },
  Widget: {
    'Ref: Service request': 'Szolgáltatásigénylés',
    'Ref: Placeholder body': 'Jelenleg nincs szolgáltatási kérelem',
    'Ref: New request button': 'Új kérés',
  },
  QrLandingPage: {
    'Ref: Notification title': 'Megváltoztatta a helyét',
    'Ref: Notification info': 'Most a {site name}',
    'Ref: Welcome': 'Üdvözöljük! {wave_emoji}',
    'Ref: Create service request text': 'Észrevett valamit, amit jelenteni szeretne?',
    'Create a service request': 'Hozzon létre egy szolgáltatási kérelmet',
    'Ref: Share feedback': 'Ossza meg visszajelzését',
    'Ref: Share text': 'Szeretnél megosztani velünk valamit?',
    'Go to home': 'Menj haza',
    'Ref: Error body':
      'A beolvasott QR-kód már nem működik. Kérjük, próbálja újra később, vagy lépjen kapcsolatba az ügyfélszolgálattal.',
  },
  QrLandingPageError: {
    'Ref: Error title': 'Hiba történt',
    'Ref: Error body':
      'A beolvasott QR-kód már nem működik. Kérjük, próbálja újra később, vagy lépjen kapcsolatba az ügyfélszolgálattal.',
    'Go to home': 'Menj haza',
  },
  ManualDescribe: {
    'Ref: Page title': 'Írja le kérését',
    'Ref: Required fields: header': 'Kérjük, adjon további információkat',
    'Ref: Required fields': 'A részletek mezőben a következő információkat kell megadni:',
    'Ref: Required field: title': 'Összegzés szükséges.',
    'Ref: Required field: description': 'Leírás szükséges.',
    'Ref: Required field: category': 'Kategória szükséges.',
    'Ref: Required field: buildingText': 'Épületre van szükség.',
    'Ref: Invalid field: category': 'Feltéve, hogy a kategória érvénytelen.',
    'Ref: Your request': 'Kérésed',
    'Ref: Description message': 'Minél részletesebb vagy, annál jobban tudunk támogatni téged.',
    'Ref: Do you have a location QR code': 'Van egy hely QR -kódod?',
    'Ref: Scan QR Code': 'QR kód beolvasása',
    'Ref: Please select category': 'Kérjük, válassza ki a Kategóriát',
    'Ref: Please select site': 'Kérjük, válassza ki a webhelyet',
    'Ref: building': 'Épület',
    'Ref: room': 'Szoba',
    'Ref: Scan error': 'Beolvasási hiba',
    'Ref: The QR code is not valid': 'A QR -kód nem érvényes',
    'Ref: Add location with QR Scanner': 'Adja hozzá a helyet a QR szkennerrel',
    'Ref: Description': 'Leírás',
    'Ref: Forbidden error': 'Sajnáljuk, ez a QR -kód nem egyezik a regisztrált helyével.',
    'Ref: Invalid error': 'Sajnáljuk, úgy tűnik, hogy ez a QR -kód már nem érvényes.',
  },
};
export default labels;
