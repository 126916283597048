const labels = {
  All: {
    'status: all': 'Все',
    'status: open': 'открыто',
    'status: pending': 'В ожидании',
    'status: closed': 'Закрыто',
    Location: 'Расположение',
    Subject: 'Тема',
    Description: 'Ваш подробный запрос',
    'Ref: description field label': 'Описание',
    'Ref: Title': 'Заголовок',
    phone_number: 'Твой номер телефона',
    request_category: 'Запрос категории',
    Attachments: 'Вложения',
    'request service': 'Сервис запроса',
    'New service request': 'Создайте еще один запрос',
    'Go to home': 'Иди домой',
    'Back to this service request': 'Вернуться к этому запросу на обслуживание',
    'Back to service requests': 'Вернуться к запросам на обслуживание',
    Feedback: 'Обратная связь',
    'Ref: Request category': 'Категория',
    Cleaning: 'Очистка',
    'Laundry services': 'Услуги прачечной',
    'Grounds maintenance': 'Обслуживание оснований',
    'Soft services': 'Мягкие услуги',
    'Hard services': 'Жесткие услуги',
    'Pest control services': 'Услуги по борьбе с вредителями',
    'Waste management': 'Управление отходами',
    'Asset Lifecycle Services': 'Услуги жизненного цикла активов',
    'There are no updates for this request': 'Для этого запроса нет обновлений',
    'Transportation Services': 'Транспортное обслуживание',
    'Ref: you pronoun': '(ты)',
    'Ref: SR raised for': 'Пострадавшего по этому запросу',
    'Ref: Select a location': 'Выберите местоположение',
    'Ref: Your request': 'Ваш запрос',
    'Ref: Summary': 'Резюме',
    'Ref: site': 'Сайт',
  },
  Comment: {
    'Ref: Page title': 'Обновление с нами',
    'Ref: please update us with details':
      'Более подробная информация для связи с нами? Комментарий? Чем больше мы знаем о вашем запросе, тем лучше мы можем поддержать вас.',
  },
  Confirm: {
    'Ref: Page title': 'Подтвердите свой запрос',
    'Ref: next': 'Представлять на рассмотрение',
    summary: 'Резюме',
    'Ref: Save as my preferred location': 'Сохранить как мое предпочтительное место',
  },
  Describe: {
    'Ref: Page title': 'Опишите ваш запрос',
    'Ref: Required fields: header': 'Пожалуйста, предоставьте дополнительную информацию',
    'Ref: Required fields': 'Следующая информация должна быть предоставлена ​​внутри поля деталей:',
    'Ref: Required field: title': 'Требуется название.',
    'Ref: Required field: description': 'Описание требуется.',
    'Ref: Required field: phone': 'Требуется номер телефона.',
    'Ref: Required field: category': 'Требуется категория.',
    'Ref: Invalid field: category': 'Предоставленная категория недействительна.',
    'Ref: Request template field label': 'Популярные запросы',
    'Ref: Request template field placeholder': 'Чистый лист',
    your_request: 'Ваш запрос',
    'Ref: Description message': 'Чем более подробно вы, тем лучше мы можем поддержать вас.',
    phone_number:
      'Ваш номер телефона (пожалуйста, используйте свой профессиональный телефон, если у вас есть)',
    'Ref: Affected person': 'Пострадавшего по этому запросу',
    'Ref: No affected person results': 'Ни один подходящий человек запрашивался критерии',
    'Ref: Notification title': 'Вы изменили местоположение',
    'Ref: Notification info': 'Вы сейчас в {site name}',
    'Ref: Add photos': 'Добавить фотографии',
    'Ref: Your location': 'Ваше местоположение',
    'Ref: limit field: title': 'заголовок должен содержать менее {ref} символов',
    'Ref: limit field: description': 'описание должно содержать менее {ref} символов',
  },
  Details: {
    'Ref: Page title': 'Детали запроса',
    Details: 'Детали',
    Updates: 'Обновления',
    'Update us': 'Обновление с нами',
    'Ref: timeline': 'График',
    'Ref: attachments': 'Вложения',
    'Ref: location': 'Место нахождения',
    'Ref: createdOn': 'Создано на',
    'Ref: reference': 'Справка',
    'Feedback was submitted': 'Отзыв отправлен',
    'Ref: sent': 'Отправил',
    'Ref: received': 'Полученный',
    'Ref: inProgress': 'В ходе выполнения',
    'Ref: completed': 'Завершенный',
    'Ref: conversation': 'Беседа',
    'Ref: Priority': 'Приоритет',
    'Ref: Target start date': 'Целевая дата начала',
    'Ref: Target completion date': 'Дата завершения выполнения задания',
    'Ref: Priority fields placeholder message':
      'Он будет заполнен после проверки Справочная служба',
  },
  Failed: {
    'Ref: Body':
      'Произошла ошибка, и ваш запрос на обслуживание не был создан. Если эта ошибка сохраняется, пожалуйста, свяжитесь с вашим Справочная служба.',
    'Ref: Tabtitle': 'Запрос на обслуживание не удалось создать',
  },
  Home: { 'Ref: Page title': 'Запросы' },
  RequestFeedback: {
    'Ref: Page title': 'Обратная связь',
    'Ref: Feedback headline': 'Пожалуйста, поделитесь своими отзывами',
    'Ref: Feedback title': 'Обратная связь о SR # {Ref}',
    'Ref: Question: general sentiment':
      'В целом, насколько вы удовлетворены тем, как Sodexo обрабатываются ваш запрос на обслуживание?',
    'Ref: Question: answer quality': 'Как бы вы оценили качество сервиса, который вы получили?',
    'Ref: Question: staff interaction':
      'Как бы вы оценили ваши взаимодействия с нашими сотрудниками?',
    'Ref: Question: efficiency': 'Как бы вы оценили эффективность нашей работы?',
    'Ref: Question: open': 'Комментарий',
    'Ref: Open question placeholder': 'Что мы сделали хорошо? Где мы можем улучшить?',
    no_comment: 'Нет комментариев',
  },
  FeedbackSuccess: {
    'Ref: Body':
      'Спасибо за ваше время, чтобы оставить комментарий, ваше мнение очень важно для нас, чтобы улучшить ваш опыт.',
  },
  Locate: {
    'Ref: Page title': 'Найдите свой запрос',
    'Ref: Top bar title': 'Найдите свой запрос',
    'Ref: Select this location': 'Выберите это место',
    drill: 'просматривать',
    'Ref: Scanner button': 'Расположение с QR? Сканировать его',
    'Ref: Scanner header': 'Пожалуйста, сканируйте ваше местоположение QR',
    'Sorry, this location could not be found.': 'Извините, это место не может быть найдено.',
    'Close scanner': 'Закрыть сканер',
    'Ref: Switch site component intro sentence': 'Вы запрашиваете услугу на сайте {site name}.',
    'Ref: search location by keywords': 'Поиск по месту',
    'Ref: Enter Location name': 'Введите имя местоположения',
    'Ref: Enter manual location': 'Введите местоположение вручную',
    'Ref: Location name': 'Название местоположения',
    'Ref: No results': 'Мы не могли найти ваше местоположение. Вы можете ввести его вручную.',
    'Ref: Up one level': 'До одного уровня',
    'Ref: Navigate': 'Навигация',
    'Ref: Select': 'Выбирать',
    'Ref: Selected Location': 'Выбранное местоположение',
    'Ref: Preferred Location': 'Предпочтительное Местоположение',
    'Ref: Building': 'Строительство',
    'Ref: Floor': 'Пол',
    'Ref: Aisle': 'Ряд',
    'Ref: Room': 'Номер',
    'Ref: Notification title': 'Вы изменили местоположение',
    'Ref: Notification info': 'Вы сейчас в {site name}',
    'Ref: Scanner error header': 'Ошибка сканирования',
    'Ref: Scan QR Code': 'Сканировать QR -код',
    'Ref: Error body': 'Этот QR -код не является действительным кодом',
    'Ref: Loading Locations Warning':
      'Пожалуйста, подождите, пока мы готовим ваш опыт для первого запроса на этом сайте.',
    'Ref: Search Result is limited to:':
      'Результаты поиска ограничены {limit}. Пожалуйста, уточните критерии поиска.',
    'Ref: Open the location search field': 'Откройте поле поиска местоположения',
  },
  Success: {
    'Ref: Body':
      'Ваш запрос на обслуживание был отправлен и обрабатывается. Он покажет в этой заявке в ближайшее время. Вы получите записку, когда она будет решена. В то же время вы можете последовать его статусу и добавить комментарии к этому через это приложение.',
    'Ref: Tabtitle': 'Запрос на обслуживание создан',
    'Ref: Download app title': 'Упростите запросы на обслуживание',
    'Ref: Download app subtitle': 'Загрузите наше приложение и улучшите свои впечатления',
    'Ref: Download app button': 'Загрузите приложение',
  },
  Tutorials: {
    'Ref: Tutorial: title': 'Это ваш концентратор поддержки на рабочем месте',
    'Ref: Tutorial: tabname': 'Запросы',
    'Ref: Tutorial: body':
      'Мы здесь чтобы помочь вам. Пожалуйста, зарегистрируйте один запрос за запись.',
    "What's new: title": 'Найдите ваше местоположение легко',
    "What's new: body":
      'Теперь вы можете отсканировать местоположение QR, сверлить уровни местоположения или просто выберите из наших интеллектуальных предложений.',
  },
  Widget: {
    'Ref: Service request': 'Запрос на обслуживание',
    'Ref: Placeholder body': 'Нет запроса на обслуживание на мгновение',
    'Ref: New request button': 'Новый запрос',
  },
  QrLandingPage: {
    'Ref: Notification title': 'Вы изменили местоположение',
    'Ref: Notification info': 'Вы сейчас в {site name}',
    'Ref: Welcome': 'Добро пожаловать! {wave_emoji}',
    'Ref: Create service request text': 'Заметили что-то, о чем хотите сообщить?',
    'Create a service request': 'Создать запрос на обслуживание',
    'Ref: Share feedback': 'Поделиться отзывом',
    'Ref: Share text': 'Хотите чем-то поделиться с нами?',
    'Go to home': 'Иди домой',
    'Ref: Error body':
      'QR-код, который вы отсканировали, больше не работает. Пожалуйста, повторите попытку позже или обратитесь в службу поддержки.',
  },
  QrLandingPageError: {
    'Ref: Error title': 'Произошла ошибка',
    'Ref: Error body':
      'QR-код, который вы отсканировали, больше не работает. Пожалуйста, повторите попытку позже или обратитесь в службу поддержки.',
    'Go to home': 'Иди домой',
  },
  ManualDescribe: {
    'Ref: Page title': 'Опишите свой запрос',
    'Ref: Required fields: header': 'Пожалуйста, предоставьте дополнительную информацию',
    'Ref: Required fields':
      'Следующая информация должна быть предоставлена ​​в поле «Подробности»:',
    'Ref: Required field: title': 'Требуется резюме.',
    'Ref: Required field: description': 'Описание требуется.',
    'Ref: Required field: category': 'Требуется категория.',
    'Ref: Required field: buildingText': 'Требуется здание.',
    'Ref: Invalid field: category': 'При условии категории недействительна.',
    'Ref: Your request': 'Ваш запрос',
    'Ref: Description message': 'Чем более подробно вы, тем лучше мы можем поддержать вас.',
    'Ref: Do you have a location QR code': 'У вас есть местоположение QR -код?',
    'Ref: Scan QR Code': 'Сканировать QR -код',
    'Ref: Please select category': 'Пожалуйста, выберите категорию',
    'Ref: Please select site': 'Пожалуйста, выберите сайт',
    'Ref: building': 'Строительство',
    'Ref: room': 'Номер',
    'Ref: Scan error': 'Ошибка сканирования',
    'Ref: The QR code is not valid': 'QR -код недопустим',
    'Ref: Add location with QR Scanner': 'Добавить место с помощью сканера QR',
    'Ref: Description': 'Описание',
    'Ref: Forbidden error':
      'Мы извиняемся, этот QR -код не соответствует вашему зарегистрированному местоположению.',
    'Ref: Invalid error':
      'Мы извиняемся, кажется, что этот QR -код больше не является действительным.',
  },
};
export default labels;
