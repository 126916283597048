const labels = {
  All: {
    'status: all': 'Allemaal',
    'status: open': 'Open',
    'status: pending': 'In afwachting',
    'status: closed': 'Gesloten',
    Location: 'Locatie',
    Subject: 'Onderwerp',
    Description: 'Uw gedetailleerde aanvraag',
    'Ref: description field label': 'Beschrijving',
    'Ref: Title': 'Titel',
    phone_number: 'Uw telefoon nummer',
    request_category: 'Categorie aanvraag',
    Attachments: 'Bijlagen',
    'request service': 'Service aanvragen',
    'New service request': 'Maak een andere aanvraag',
    'Go to home': 'Ga naar de startpagina',
    'Back to this service request': 'Terug naar deze service aanvraag',
    'Back to service requests': 'Terug naar de startpagina',
    Feedback: 'Terugkoppeling',
    'Ref: Request category': 'Categorie',
    Cleaning: 'Schoonmaak',
    'Laundry services': 'Wasserij',
    'Grounds maintenance': 'Groenonderhoud',
    'Soft services': 'Soft Services',
    'Hard services': 'Hard Services',
    'Pest control services': 'Ongediertebestrijding',
    'Waste management': 'Afvalbeheer',
    'Asset Lifecycle Services': 'Asset Lifecycle Services',
    'There are no updates for this request': 'Er zijn geen updates voor deze aanvraag',
    'Transportation Services': 'Transportdienst',
    'Ref: you pronoun': '(jij)',
    'Ref: SR raised for': 'Betrokken persoon bij deze aanvraag',
    'Ref: Select a location': 'Selecteer een locatie',
    'Ref: Your request': 'Uw aanvraag',
    'Ref: Summary': 'Overzicht',
    'Ref: site': 'Site',
  },
  Comment: {
    'Ref: Page title': 'update-ons',
    'Ref: please update us with details':
      'Wilt u meer informatie delen? Hoe meer we weten over uw aanvraag, hoe beter we kunnen u ondersteunen.',
  },
  Confirm: {
    'Ref: Page title': 'Bevestig uw aanvraag',
    'Ref: next': 'Indienen',
    summary: 'Samenvatting',
    'Ref: Save as my preferred location': 'Bewaar als mijn favoriete locatie',
  },
  Describe: {
    'Ref: Page title': 'Beschrijf uw aanvraag',
    'Ref: Required fields: header': 'Geef aanvullende informatie',
    'Ref: Required fields': 'De volgende informatie moet worden verstrekt in het veld Details:',
    'Ref: Required field: title': 'Een titel is vereist.',
    'Ref: Required field: description': 'Een beschrijving is vereist.',
    'Ref: Required field: phone': 'Een telefoonnummer is vereist.',
    'Ref: Required field: category': 'Een categorie is vereist.',
    'Ref: Invalid field: category': 'Ingevulde categorie is ongeldig.',
    'Ref: Request template field label': 'Populaire aanvragen',
    'Ref: Request template field placeholder': 'Blanco pagina',
    your_request: 'Uw aanvraag',
    'Ref: Description message': 'Hoe meer details je deelt, hoe beter we je kunnen ondersteunen.',
    phone_number: 'Uw telefoonnummer (gebruik uw professionele telefoon als u er een hebt)',
    'Ref: Affected person': 'Betrokken persoon bij deze aanvraag',
    'Ref: No affected person results': 'Persoon komt niet overeen met gevraagde criteria',
    'Ref: Notification title': 'Je hebt je locatie veranderd',
    'Ref: Notification info': 'U bent nu op {site name}',
    'Ref: Add photos': "Voeg foto's toe",
    'Ref: Your location': 'Jouw locatie',
    'Ref: limit field: title': 'titel moet minder dan {ref} tekens zijn',
    'Ref: limit field: description': 'beschrijving moet minder dan {ref} tekens bevatten',
  },
  Details: {
    'Ref: Page title': 'Details van de aanvraag',
    Details: 'Details',
    Updates: 'Updates',
    'Update us': 'update-ons',
    'Ref: timeline': 'Tijdlijn',
    'Ref: attachments': 'Bijlagen',
    'Ref: location': 'Locatie',
    'Ref: createdOn': 'Gemaakt op',
    'Ref: reference': 'Referentie',
    'Feedback was submitted': 'Feedback verzonden',
    'Ref: sent': 'Verzonden',
    'Ref: received': 'Ontvangen',
    'Ref: inProgress': 'Bezig',
    'Ref: completed': 'Voltooid',
    'Ref: conversation': 'Gesprek',
    'Ref: Priority': 'Prioriteit',
    'Ref: Target start date': 'Voorziene startdatum',
    'Ref: Target completion date': 'Voorziene einddatum',
    'Ref: Priority fields placeholder message':
      'Het wordt ingevuld eenmaal gevalideerd door de Help Desk',
  },
  Failed: {
    'Ref: Body':
      'Er is een fout opgetreden en uw serviceverzoek is niet gemaakt. Als deze fout aanhoudt, neem dan contact op met uw Help Desk.',
    'Ref: Tabtitle': 'Service aanvraag kon niet worden aangemaakt',
  },
  Home: { 'Ref: Page title': 'Aanvragen' },
  RequestFeedback: {
    'Ref: Page title': 'feedback',
    'Ref: Feedback headline': 'Deel uw feedback',
    'Ref: Feedback title': 'Feedback over SR # {Ref}',
    'Ref: Question: general sentiment':
      'Hoe tevreden bent u over hoe Sodexo uw aanvraag behandeld?',
    'Ref: Question: answer quality': 'Hoe waardeert u de kwalitet van de dienstverlening?',
    'Ref: Question: staff interaction': 'Hoe waardeert u onze medewerkers?',
    'Ref: Question: efficiency': 'Hoe waardeert u onze efficiëntie?',
    'Ref: Question: open': 'Commentaar',
    'Ref: Open question placeholder': 'Wat was goed? Waar kunnen we verbeteren?',
    no_comment: 'Geen commentaar',
  },
  FeedbackSuccess: {
    'Ref: Body':
      'Bedankt voor uw reactie. Uw feedback is belangrijk voor ons om uw ervaring te verbeteren.',
  },
  Locate: {
    'Ref: Page title': 'Selecteer een locatie',
    'Ref: Top bar title': 'Zoek uw aanvraag',
    'Ref: Select this location': 'Selecteer deze locatie',
    drill: 'surfen',
    'Ref: Scanner button': 'Locatie met een QR? Scan de QR code',
    'Ref: Scanner header': 'Scan alstublieft uw locatie QR',
    'Sorry, this location could not be found.': 'Sorry, deze locatie kon niet worden gevonden.',
    'Close scanner': 'Sluit de scanner',
    'Ref: Switch site component intro sentence': 'U vraagt ​​service op deze site {site name}.',
    'Ref: search location by keywords': 'Zoek een locatie',
    'Ref: Enter Location name': 'Voer de locatienaam in',
    'Ref: Enter manual location': 'Voer de locatie handmatig in',
    'Ref: Location name': 'Locatie naam',
    'Ref: No results': 'We konden uw locatie niet vinden. Misschien wilt u het handmatig invoeren.',
    'Ref: Up one level': 'Een level omhoog',
    'Ref: Navigate': 'Navigeren',
    'Ref: Select': 'Selecteren',
    'Ref: Selected Location': 'Geselecteerde locatie',
    'Ref: Preferred Location': 'Voorkeurslocatie',
    'Ref: Building': 'Gebouw',
    'Ref: Floor': 'Vloer',
    'Ref: Aisle': 'Gangpad',
    'Ref: Room': 'Kamer',
    'Ref: Notification title': 'Je hebt je locatie veranderd',
    'Ref: Notification info': 'U bent nu op {site name}',
    'Ref: Scanner error header': 'Scanfout',
    'Ref: Scan QR Code': 'Scan QR-code',
    'Ref: Error body': 'Deze QR -code is geen geldige code',
    'Ref: Loading Locations Warning':
      'Wacht terwijl we ons voorbereiden voor uw eerste aanvraag op deze site.',
    'Ref: Search Result is limited to:':
      'Zoekresultaat is beperkt tot {limit}. Verfijn uw zoekcriteria.',
    'Ref: Open the location search field': 'Open het veld Location Search',
  },
  Success: {
    'Ref: Body':
      'Uw serviceverzoek is ingediend, en wordt verwerkt en wordt hier kort weergegeven. U ontvangt een bericht wanneer deze is opgelost. Ondertussen kunt u de status volgen en opmerkingen toevoegen aan deze aanvraag.',
    'Ref: Tabtitle': 'Serviceverzoek gemaakt',
    'Ref: Download app title': 'Vereenvoudig uw serviceaanvragen',
    'Ref: Download app subtitle': 'Download onze app en verbeter uw ervaring',
    'Ref: Download app button': 'Download de app',
  },
  Tutorials: {
    'Ref: Tutorial: title': 'Dit is uw werkplek helpdesk',
    'Ref: Tutorial: tabname': 'Aanvragen',
    'Ref: Tutorial: body': 'Wij zijn hier om u te helpen. Log één verzoek per invoer in.',
    "What's new: title": 'Vind uw locatie gemakkelijk',
    "What's new: body":
      'U kunt nu een locatie-QR scannen, locatieniveaus scannen of gewoon kiezen uit onze suggesties.',
  },
  Widget: {
    'Ref: Service request': 'Aanvraag tot dienstverlening',
    'Ref: Placeholder body': 'Geen aanvraag op dit moment',
    'Ref: New request button': 'Nieuwe aanvraag',
  },
  QrLandingPage: {
    'Ref: Notification title': 'Je hebt je locatie veranderd',
    'Ref: Notification info': 'U bent nu op {site name}',
    'Ref: Welcome': 'Welkom! {wave_emoji}',
    'Ref: Create service request text': 'Heeft u iets opgemerkt dat u wilt melden?',
    'Create a service request': 'Maak een aanvraag aan ',
    'Ref: Share feedback': 'Deel een feedback',
    'Ref: Share text': 'Wilt u iets met ons delen?',
    'Go to home': 'Ga naar de startpagina',
    'Ref: Error body':
      'De QR-code die u hebt gescand, werkt niet meer. Probeer het later opnieuw of neem contact op met de ondersteuning.',
  },
  QrLandingPageError: {
    'Ref: Error title': 'Er is een fout opgetreden',
    'Ref: Error body':
      'De QR-code die u hebt gescand, werkt niet meer. Probeer het later opnieuw of neem contact op met de ondersteuning.',
    'Go to home': 'Ga naar de startpagina',
  },
  ManualDescribe: {
    'Ref: Page title': 'Beschrijf uw aanvraag',
    'Ref: Required fields: header': 'Geef aanvullende informatie',
    'Ref: Required fields': 'De volgende informatie moet binnen het veld Details worden verstrekt:',
    'Ref: Required field: title': 'Een samenvatting is vereist.',
    'Ref: Required field: description': 'Een beschrijving is vereist.',
    'Ref: Required field: category': 'Een categorie is vereist.',
    'Ref: Required field: buildingText': 'Een gebouw is vereist.',
    'Ref: Invalid field: category': 'Op voorwaarde dat de categorie ongeldig is.',
    'Ref: Your request': 'Uw aanvraag',
    'Ref: Description message': 'Hoe meer details, hoe beter we je kunnen helpen.',
    'Ref: Do you have a location QR code': 'Heeft u een locatie QR -code?',
    'Ref: Scan QR Code': 'Scan QR-code',
    'Ref: Please select category': 'Selecteer de categorie',
    'Ref: Please select site': 'Selecteer de site',
    'Ref: building': 'Gebouw',
    'Ref: room': 'Kamer',
    'Ref: Scan error': 'Scanfout',
    'Ref: The QR code is not valid': 'De QR -code is niet geldig',
    'Ref: Add location with QR Scanner': 'Voeg locatie toe met QR -scanner',
    'Ref: Description': 'Beschrijving',
    'Ref: Forbidden error':
      'Het spijt ons, deze QR -code komt niet overeen met uw geregistreerde locatie.',
    'Ref: Invalid error': 'Het spijt ons, het lijkt erop dat deze QR -code niet langer geldig is.',
  },
};
export default labels;
