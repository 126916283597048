const labels = {
  All: {
    'status: all': 'toutes',
    'status: open': 'Ouvertes',
    'status: pending': 'en attente',
    'status: closed': 'Fermées',
    Location: 'Emplacement',
    Subject: 'Objet',
    Description: 'Détails de votre demande',
    'Ref: description field label': 'Description',
    'Ref: Title': 'Titre',
    phone_number: 'Votre numéro de téléphone',
    request_category: 'Catégorie de la demande',
    Attachments: 'Pièces jointes',
    'request service': 'Faire une demande',
    'New service request': 'Créer une autre demande',
    'Go to home': 'Aller à la maison',
    'Back to this service request': 'Retour à cette demande',
    'Back to service requests': 'Retour aux demandes de service',
    Feedback: 'Votre avis',
    'Ref: Request category': 'Catégorie',
    Cleaning: 'Nettoyage',
    'Laundry services': 'Services de blanchisserie',
    'Grounds maintenance': 'Entretien des terrains',
    'Soft services': 'Soft Services',
    'Hard services': 'Services difficiles',
    'Pest control services': 'Services de lutte antiparasitaire',
    'Waste management': 'Gestion des déchets',
    'Asset Lifecycle Services': 'Services de gestion des équipements',
    'There are no updates for this request': "Il n'y a pas de mises à jour pour cette demande",
    'Transportation Services': 'Service de transport',
    'Ref: you pronoun': '(vous)',
    'Ref: SR raised for': 'Personne affectée par cette demande',
    'Ref: Select a location': 'Sélectionnez un emplacement',
    'Ref: Your request': 'Votre demande',
    'Ref: Summary': 'Synthèse',
    'Ref: site': 'Site',
  },
  Comment: {
    'Ref: Page title': 'Nouvel élément',
    'Ref: please update us with details':
      "Plus d'informations à nous communiquer? Un commentaire? Plus nous en savons sur votre demande, mieux nous pouvons vous accompagner.",
  },
  Confirm: {
    'Ref: Page title': 'Confirmez votre demande',
    'Ref: next': 'Soumettre',
    summary: 'Validez votre demande',
    'Ref: Save as my preferred location': "Enregistrer en tant qu'emplacement préféré",
  },
  Describe: {
    'Ref: Page title': 'Décrivez votre demande',
    'Ref: Required fields: header': 'Veuillez fournir des informations supplémentaires',
    'Ref: Required fields':
      'Les informations suivantes doivent être fournies dans le champ de détails :',
    'Ref: Required field: title': 'Un titre est requis.',
    'Ref: Required field: description': 'Une description est requise.',
    'Ref: Required field: phone': 'Un numéro de téléphone est requis.',
    'Ref: Required field: category': 'Une catégorie est requise.',
    'Ref: Invalid field: category': "La catégorie fournie n'est pas valide.",
    'Ref: Request template field label': 'Demandes populaires',
    'Ref: Request template field placeholder': 'Feuille blanche',
    your_request: 'Votre demande',
    'Ref: Description message': 'Plus votre demande est détaillée, meilleur sera notre support',
    phone_number:
      'Votre numéro de téléphone (veuillez utiliser votre téléphone professionnel si vous en avez un)',
    'Ref: Affected person': 'Personne affectée par cette demande',
    'Ref: No affected person results': 'La personne ne correspond aux critères demandés',
    'Ref: Notification title': "Vous avez changé l'emplacement",
    'Ref: Notification info': 'Vous êtes maintenant sur {site name}',
    'Ref: Add photos': 'Ajouter des photos',
    'Ref: Your location': 'Votre emplacement',
    'Ref: limit field: title': 'le titre doit contenir moins de {ref} caractères',
    'Ref: limit field: description': 'la description doit contenir moins de {ref} caractères',
  },
  Details: {
    'Ref: Page title': 'Détails de la demande',
    Details: 'Détails',
    Updates: 'Mises à jour',
    'Update us': 'Nouvel élément',
    'Ref: timeline': 'Chronologie',
    'Ref: attachments': 'Pièces jointes',
    'Ref: location': 'Emplacement',
    'Ref: createdOn': 'Créé sur',
    'Ref: reference': 'Référence',
    'Feedback was submitted': 'Evaluation envoyée',
    'Ref: sent': 'Envoyé',
    'Ref: received': 'Reçu',
    'Ref: inProgress': 'En cours',
    'Ref: completed': 'Complété',
    'Ref: conversation': 'Conversation',
    'Ref: Priority': 'Priorité',
    'Ref: Target start date': 'Date de début cible',
    'Ref: Target completion date': "Date d'achèvement cible",
    'Ref: Priority fields placeholder message': "Il sera rempli une fois validé par bureau d'aide",
  },
  Failed: {
    'Ref: Body':
      "Une erreur s'est produite et votre demande de service n'a pas été créée. Si cette erreur persiste, veuillez contacter votre service d'assistance.",
    'Ref: Tabtitle': "La demande de service n'a pas créé",
  },
  Home: { 'Ref: Page title': 'Demandes' },
  RequestFeedback: {
    'Ref: Page title': 'Votre avis',
    'Ref: Feedback headline': 'Veuillez partager vos commentaires',
    'Ref: Feedback title': 'Commentaires sur le SR #{ref}',
    'Ref: Question: general sentiment': 'Comment évalueriez-vous globalement notre réponse ?',
    'Ref: Question: answer quality':
      'Comment jugez-vous la qualité de la réponse apportée à votre demande?',
    'Ref: Question: staff interaction':
      'Comment jugez-vous la qualité des interactions avec notre personnel?',
    'Ref: Question: efficiency': "Comment jugez-vous notre rapidité d'exécution?",
    'Ref: Question: open': 'Commenter',
    'Ref: Open question placeholder':
      'Quels sont les points positifs? Quels sont les points à améliorer?',
    no_comment: 'Aucun commentaire',
  },
  FeedbackSuccess: {
    'Ref: Body':
      "Merci d'avoir pris le temps de laisser un avis. Vos avis nous aident à améliorer votre expérience.",
  },
  Locate: {
    'Ref: Page title': 'Titre de la demande',
    'Ref: Top bar title': 'Localisez votre demande',
    'Ref: Select this location': 'Sélectionnez cet emplacement',
    drill: 'Feuilleter',
    'Ref: Scanner button': 'Emplacement avec un QR ? Scannez-le',
    'Ref: Scanner header': 'Veuillez scanner le QR',
    'Sorry, this location could not be found.': 'Désolé, cet emplacement est introuvable.',
    'Close scanner': 'Fermer le scanneur',
    'Ref: Switch site component intro sentence':
      'Vous demandez un service sur le site {site name}.',
    'Ref: search location by keywords': 'Rechercher un emplacement',
    'Ref: Enter Location name': "Entrez le nom de l'emplacement",
    'Ref: Enter manual location': "Entrez l'emplacement manuellement",
    'Ref: Location name': "Nom de l'emplacement",
    'Ref: No results':
      "Nous n'avons pas pu trouver votre emplacement. Vous pouvez le saisir manuellement.",
    'Ref: Up one level': 'Revenir au niveau supérieur',
    'Ref: Navigate': 'Naviguer',
    'Ref: Select': 'Sélectionner',
    'Ref: Selected Location': 'Emplacement sélectionné',
    'Ref: Preferred Location': 'Emplacement préféré',
    'Ref: Building': 'Immeuble',
    'Ref: Floor': 'Etage',
    'Ref: Aisle': 'Allée',
    'Ref: Room': 'Salle',
    'Ref: Notification title': "Vous avez changé l'emplacement",
    'Ref: Notification info': 'Vous êtes maintenant sur {site name}',
    'Ref: Scanner error header': 'Erreur de scan',
    'Ref: Scan QR Code': 'Scanner le code QR',
    'Ref: Error body': "Ce code QR n'est pas valide",
    'Ref: Loading Locations Warning':
      'Veuillez patienter pendant que nous préparons votre expérience pour une première demande sur ce site.',
    'Ref: Search Result is limited to:':
      'Le résultat de la recherche est limité à {limit}. Veuillez affiner vos critères de recherche.',
    'Ref: Open the location search field': "Ouvrez le champ de recherche d'emplacement",
  },
  Success: {
    'Ref: Body':
      "Votre demande de service a été soumise et est en cours de traitement. Elle apparaîtra dans cette application sous peu. Vous recevrez une notice lorsqu'elle sera résolue. En attendant, vous pouvez suivre son statut et y ajouter des commentaires via cette application.",
    'Ref: Tabtitle': 'Demande de service créée',
    'Ref: Download app title': 'Simplifiez vos demandes de service',
    'Ref: Download app subtitle': 'Téléchargez notre application et améliorez votre expérience',
    'Ref: Download app button': "Téléchargez l'application",
  },
  Tutorials: {
    'Ref: Tutorial: title': "Bienvenue sur votre centre d'assistance au travail",
    'Ref: Tutorial: tabname': 'Demandes',
    'Ref: Tutorial: body':
      'Nous sommes là pour vous aider. Veuillez enregistrer une demande par entrée.',
    "What's new: title": 'Trouvez facilement votre emplacement',
    "What's new: body":
      'Vous pouvez désormais scanner un code QR de localisation, explorer les niveaux de localisation ou simplement sélectionner parmi nos suggestions intelligentes.',
  },
  Widget: {
    'Ref: Service request': 'Demande de service',
    'Ref: Placeholder body': 'Aucune demande de service pour le moment',
    'Ref: New request button': 'Nouvelle requête',
  },
  QrLandingPage: {
    'Ref: Notification title': "Vous avez changé l'emplacement",
    'Ref: Notification info': 'Vous êtes maintenant sur {site name}',
    'Ref: Welcome': 'Accueillir! {wave_emoji}',
    'Ref: Create service request text':
      'Vous avez remarqué quelque chose que vous souhaitez signaler ?',
    'Create a service request': 'Créer une demande de service',
    'Ref: Share feedback': 'Partager un commentaire',
    'Ref: Share text': 'Vous souhaitez partager quelque chose avec nous ?',
    'Go to home': "Revenir à la page d'accueil",
    'Ref: Error body':
      "Le code QR que vous avez scanné ne fonctionne plus. Veuillez réessayer plus tard ou contacter l'assistance.",
  },
  QrLandingPageError: {
    'Ref: Error title': 'Une erreur est survenue',
    'Ref: Error body':
      "Le code QR que vous avez scanné ne fonctionne plus. Veuillez réessayer plus tard ou contacter l'assistance.",
    'Go to home': "Aller à l'accueil",
  },
  ManualDescribe: {
    'Ref: Page title': 'Décrivez votre demande',
    'Ref: Required fields: header': 'Veuillez fournir des informations supplémentaires',
    'Ref: Required fields':
      'Les informations suivantes doivent être fournies dans le champ Détails:',
    'Ref: Required field: title': 'Un résumé est requis.',
    'Ref: Required field: description': 'Une description est requise.',
    'Ref: Required field: category': 'Une catégorie est requise.',
    'Ref: Required field: buildingText': 'Un bâtiment est requis.',
    'Ref: Invalid field: category': "La catégorie sélectionnée n'est pas valide.",
    'Ref: Your request': 'Votre demande',
    'Ref: Description message': 'Plus votre demande est détaillée, meilleur sera notre support',
    'Ref: Do you have a location QR code': 'Avez-vous un code QR pour cet emplacement ?',
    'Ref: Scan QR Code': 'Scanner le QR code',
    'Ref: Please select category': 'Veuillez sélectionner la catégorie',
    'Ref: Please select site': 'Veuillez sélectionner le site',
    'Ref: building': 'Immeuble',
    'Ref: room': 'Salle',
    'Ref: Scan error': 'Erreur de scan',
    'Ref: The QR code is not valid': "Le QR code n'est pas valide",
    'Ref: Add location with QR Scanner': "Ajouter l'emplacement avec le scanner de QR code",
    'Ref: Description': 'Description',
    'Ref: Forbidden error':
      'Nous sommes désolés, ce code QR ne correspond pas à votre emplacement enregistré.',
    'Ref: Invalid error': 'Nous sommes désolés, il semble que ce code QR ne soit plus valide.',
  },
};
export default labels;
