const labels = {
  All: {
    'status: all': 'visi',
    'status: open': 'Atviras',
    'status: pending': 'Kol',
    'status: closed': 'Uždaryta',
    Location: 'vieta',
    Subject: 'tema',
    Description: 'Jūsų išsamus prašymas',
    'Ref: description field label': 'apibūdinimas',
    'Ref: Title': 'Pavadinimas',
    phone_number: 'Jūsų telefono numeris',
    request_category: 'prašymas Kategorija',
    Attachments: 'priedai',
    'request service': 'Prašymo tarnyba',
    'New service request': 'Sukurkite kitą užklausą',
    'Go to home': 'Eiti namo',
    'Back to this service request': 'Grįžti į šį paslaugų užklausą',
    'Back to service requests': 'Atgal į paslaugų užklausas',
    Feedback: 'Atsiliepimas',
    'Ref: Request category': 'Kategorija',
    Cleaning: 'Valymas',
    'Laundry services': 'Skalbimo paslaugos',
    'Grounds maintenance': 'Priežiūra.  T',
    'Soft services': 'Minkštos paslaugos',
    'Hard services': 'Sunkios paslaugos',
    'Pest control services': 'Kenkėjų kontrolės paslaugos',
    'Waste management': 'Atliekų tvarkymas',
    'Asset Lifecycle Services': 'Turto gyvavimo ciklo paslaugos',
    'There are no updates for this request': 'Šios užklausos atnaujinimų nėra',
    'Transportation Services': 'Transporto paslaugos.  T',
    'Ref: you pronoun': '(tu)',
    'Ref: SR raised for': 'Šis prašymas paveikė asmenį',
    'Ref: Select a location': 'Pasirinkite vietą',
    'Ref: Your request': 'Jūsų užklausa',
    'Ref: Summary': 'Santrauka',
    'Ref: site': 'Svetainė',
  },
  Comment: {
    'Ref: Page title': 'Atnaujinti mus',
    'Ref: please update us with details':
      'Daugiau informacijos bendrauti su mumis? Komentarą Kuo daugiau mes žinome apie savo prašymą, tuo geriau mes galime padėti jums.',
  },
  Confirm: {
    'Ref: Page title': 'Patvirtinkite savo prašymą',
    'Ref: next': 'Pateikti',
    summary: 'santrauka',
    'Ref: Save as my preferred location': 'Išsaugokite kaip mano pageidaujamą vietą',
  },
  Describe: {
    'Ref: Page title': 'Aprašykite savo prašymą',
    'Ref: Required fields: header': 'Pateikite papildomos informacijos',
    'Ref: Required fields': 'Informacijos srityje turėtų būti pateikta ši informacija:',
    'Ref: Required field: title': 'Reikalingas pavadinimas.',
    'Ref: Required field: description': 'Apibūdinimas yra privalomi.',
    'Ref: Required field: phone': 'Reikalingas telefono numeris.',
    'Ref: Required field: category': 'Reikalinga kategorija.',
    'Ref: Invalid field: category': 'Pateikta kategorija negalioja.',
    'Ref: Request template field label': 'Populiarūs prašymai.',
    'Ref: Request template field placeholder': 'Tuščias lapas',
    your_request: 'Jūsų užklausa',
    'Ref: Description message': 'Kuo išsamesnis esate, tuo geriau galime jus palaikyti.',
    phone_number: 'Jūsų telefono numeris (jei turite jį, naudokite profesionalų telefoną)',
    'Ref: Affected person': 'Šis prašymas paveikė asmenį',
    'Ref: No affected person results': 'Nė vienas asmuo, atitinkantis neprašytus kriterijus',
    'Ref: Notification title': 'Jūs pakeitėte vietą',
    'Ref: Notification info': 'Dabar esate {site name}',
    'Ref: Add photos': 'Pridėkite nuotraukų',
    'Ref: Your location': 'Jūsų vieta',
    'Ref: limit field: title': 'pavadinimas turi būti trumpesnis nei {ref} simbolių',
    'Ref: limit field: description': 'aprašymas turi būti trumpesnis nei {ref} simbolių',
  },
  Details: {
    'Ref: Page title': 'Prašyti išsamios informacijos',
    Details: 'detalės',
    Updates: 'Atnaujinimai.',
    'Update us': 'Atnaujinti mus',
    'Ref: timeline': 'Laiko juosta',
    'Ref: attachments': 'Priedai',
    'Ref: location': 'Vieta',
    'Ref: createdOn': 'Sukurtas',
    'Ref: reference': 'Nuoroda',
    'Feedback was submitted': 'Atsiliepimas išsiųstas',
    'Ref: sent': 'Išsiųstas',
    'Ref: received': 'Gauta',
    'Ref: inProgress': 'Vyksta',
    'Ref: completed': 'Baigta',
    'Ref: conversation': 'Pokalbis',
    'Ref: Priority': 'Prioritetas',
    'Ref: Target start date': 'Tikslo pradžios data',
    'Ref: Target completion date': 'Tikslo pabaigos data',
    'Ref: Priority fields placeholder message':
      'Jis bus apgyvendintas, kai tik patvirtins pagalbos tarnyba',
  },
  Failed: {
    'Ref: Body':
      'Įvyko klaida ir jūsų paslaugos užklausa nebuvo sukurta. Jei ši klaida išlieka, kreipkitės į savo pagalbos tarnybą.',
    'Ref: Tabtitle': 'Paslaugos užklausa nepavyko sukurti',
  },
  Home: { 'Ref: Page title': 'Užklausos' },
  RequestFeedback: {
    'Ref: Page title': 'Atsiliepimas',
    'Ref: Feedback headline': 'Prašau pasidalyti savo atsiliepimais',
    'Ref: Feedback title': 'Atsiliepimai apie SR # {Ref}',
    'Ref: Question: general sentiment':
      'Apskritai, kiek esate patenkintas kaip Sodexo tvarkomi jūsų aptarnavimo užklausą?',
    'Ref: Question: answer quality': 'Kaip Jūs vertinate teikiamų paslaugų gavote kokybę?',
    'Ref: Question: staff interaction': 'Kaip Jūs vertinate savo sąveiką su mūsų darbuotojų?',
    'Ref: Question: efficiency': 'Kaip Jūs vertinate mūsų efektyvumą?',
    'Ref: Question: open': 'komentaras',
    'Ref: Open question placeholder': 'Ką mes darome gerai? Kur mes galime pagerinti?',
    no_comment: 'Be komentarų',
  },
  FeedbackSuccess: {
    'Ref: Body':
      'Ačiū, kad radote savo laiko palikti komentarą, jūsų atsiliepimai yra mums svarbu pagerinti savo patirtį.',
  },
  Locate: {
    'Ref: Page title': 'Raskite savo prašymą',
    'Ref: Top bar title': 'Raskite savo užklausą',
    'Ref: Select this location': 'Pasirinkite šią vietą',
    drill: 'Naršyti',
    'Ref: Scanner button': 'Vieta su QR? Nuskaityti',
    'Ref: Scanner header': 'Prašome nuskaityti savo vietą QR',
    'Sorry, this location could not be found.': 'Atsiprašome, ši vieta negalėjo būti rasta.',
    'Close scanner': 'Uždaryti skaitytuvą',
    'Ref: Switch site component intro sentence': 'Jūs prašote paslaugos svetainėje {site name}.',
    'Ref: search location by keywords': 'Ieškokite vietos',
    'Ref: Enter Location name': 'Įveskite vietos pavadinimą',
    'Ref: Enter manual location': 'Įveskite vietą rankiniu būdu',
    'Ref: Location name': 'Vietos pavadinimas',
    'Ref: No results': 'Mes negalėjome rasti jūsų vietos. Galbūt norėsite jį įvesti rankiniu būdu.',
    'Ref: Up one level': 'Iki vieno lygio',
    'Ref: Navigate': 'Rodyti kelią',
    'Ref: Select': 'Pasirinkite',
    'Ref: Selected Location': 'Pasirinkta vieta',
    'Ref: Preferred Location': 'Norima Vietovė',
    'Ref: Building': 'Pastatas',
    'Ref: Floor': 'Grindys',
    'Ref: Aisle': 'Praėjimas',
    'Ref: Room': 'Kambarys',
    'Ref: Notification title': 'Jūs pakeitėte vietą',
    'Ref: Notification info': 'Dabar esate {site name}',
    'Ref: Scanner error header': 'Nuskaitymo klaida',
    'Ref: Scan QR Code': 'Nuskaityti QR kodą',
    'Ref: Error body': 'Šis QR kodas nėra galiojantis kodas',
    'Ref: Loading Locations Warning':
      'Palaukite, kol ruošiame jūsų patirtį dėl pirmojo prašymo šioje svetainėje.',
    'Ref: Search Result is limited to:':
      'Paieškos rezultatas apsiriboja {limit}. Patobulinkite paieškos kriterijus.',
    'Ref: Open the location search field': 'Atidarykite vietos paieškos lauką',
  },
  Success: {
    'Ref: Body':
      'Jūsų paslaugos užklausa buvo pateikta ir apdorojama. Netrukus jis bus rodomas šioje paraiškoje. Jūs gausite pastabą, kai jis bus išspręstas. Tuo tarpu galite sekti savo būseną ir pridėti komentarus per šią programą.',
    'Ref: Tabtitle': 'Sukurtas paslaugų užklausa',
    'Ref: Download app title': 'Supaprastinkite savo paslaugų užklausas',
    'Ref: Download app subtitle': 'Atsisiųskite mūsų programą ir patobulinkite savo patirtį',
    'Ref: Download app button': 'Atsisiųskite programą',
  },
  Tutorials: {
    'Ref: Tutorial: title': 'Tai yra jūsų darbo vietos palaikymo stebulė',
    'Ref: Tutorial: tabname': 'Užklausos',
    'Ref: Tutorial: body':
      'Mes esame čia, kad jums padėtume. Prašome registruoti vieną užklausą kiekviename įraše.',
    "What's new: title": 'Raskite savo vietą lengvai',
    "What's new: body":
      'Dabar galite nuskaityti vietos QR, gręžti žemyn vietos lygius arba tiesiog pasirinkite iš mūsų protingų pasiūlymų.',
  },
  Widget: {
    'Ref: Service request': 'Paslaugos užklausa',
    'Ref: Placeholder body': 'Nėra paslaugų užklausos',
    'Ref: New request button': 'Naujas prašymas',
  },
  QrLandingPage: {
    'Ref: Notification title': 'Jūs pakeitėte vietą',
    'Ref: Notification info': 'Dabar esate {site name}',
    'Ref: Welcome': 'Sveiki! {wave_emoji}',
    'Ref: Create service request text': 'Pastebėjote ką nors, apie kurį norite pranešti?',
    'Create a service request': 'Sukurkite paslaugų užklausą',
    'Ref: Share feedback': 'Pasidalykite atsiliepimu',
    'Ref: Share text': 'Norite kuo nors su mumis pasidalinti?',
    'Go to home': 'Eiti namo',
    'Ref: Error body':
      'Nuskaitytas QR kodas nebeveikia. Bandykite dar kartą vėliau arba susisiekite su palaikymo komanda.',
  },
  QrLandingPageError: {
    'Ref: Error title': 'Įvyko klaida',
    'Ref: Error body':
      'Nuskaitytas QR kodas nebeveikia. Bandykite dar kartą vėliau arba susisiekite su palaikymo komanda.',
    'Go to home': 'Eiti namo',
  },
  ManualDescribe: {
    'Ref: Page title': 'Apibūdinkite savo prašymą',
    'Ref: Required fields: header': 'Pateikite papildomos informacijos',
    'Ref: Required fields': 'Lauke „Informacija“ turėtų būti pateikta ši informacija:',
    'Ref: Required field: title': 'Reikalinga santrauka.',
    'Ref: Required field: description': 'Reikalingas aprašymas.',
    'Ref: Required field: category': 'Reikalinga kategorija.',
    'Ref: Required field: buildingText': 'Reikalingas pastatas.',
    'Ref: Invalid field: category': 'Numatoma kategorija neteisinga.',
    'Ref: Your request': 'Jūsų užklausa',
    'Ref: Description message': 'Kuo išsamesnis esate, tuo geriau galime jus palaikyti.',
    'Ref: Do you have a location QR code': 'Ar turite vietos QR kodą?',
    'Ref: Scan QR Code': 'Nuskaityti QR kodą',
    'Ref: Please select category': 'Pasirinkite kategoriją',
    'Ref: Please select site': 'Pasirinkite svetainę',
    'Ref: building': 'Pastatas',
    'Ref: room': 'Kambarys',
    'Ref: Scan error': 'Nuskaitymo klaida',
    'Ref: The QR code is not valid': 'QR kodas negalioja',
    'Ref: Add location with QR Scanner': 'Pridėkite vietą su QR skaitytuvu',
    'Ref: Description': 'apibūdinimas',
    'Ref: Forbidden error': 'Atsiprašau, šis QR kodas neatitinka jūsų registruotos vietos.',
    'Ref: Invalid error': 'Atsiprašau, atrodo, kad šis QR kodas nebegalioja.',
  },
};
export default labels;
