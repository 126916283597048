const labels = {
  All: {
    'status: all': 'Alle',
    'status: open': 'Offen',
    'status: pending': 'Ausstehend',
    'status: closed': 'Abgeschlossen',
    Location: 'Ort',
    Subject: 'Thema',
    Description: 'Ihre detaillierte Anfrage.',
    'Ref: description field label': 'Beschreibung',
    'Ref: Title': 'Titel',
    phone_number: 'Telefonnummer',
    request_category: 'Anfragekategorie',
    Attachments: 'Anhänge',
    'request service': 'Service anfragen',
    'New service request': 'Neue Serviceanfrage',
    'Go to home': 'Zur Startseite',
    'Back to this service request': 'Zurück zu dieser Serviceanfrage',
    'Back to service requests': 'Zurück zu Serviceanfragen',
    Feedback: 'Feedback',
    'Ref: Request category': 'Kategorie',
    Cleaning: 'Reinigung',
    'Laundry services': 'Wäscheservice',
    'Grounds maintenance': 'Grundstückspflege',
    'Soft services': 'Soft Services',
    'Hard services': 'Hard Services',
    'Pest control services': 'Schädlingsbekämpfungsdienste',
    'Waste management': 'Abfallwirtschaft',
    'Asset Lifecycle Services': 'Dienstleistungen für den Lebenszyklus von Anlagen',
    'There are no updates for this request': 'Es gibt keine Updates für diese Anfrage',
    'Transportation Services': 'Transportdienste',
    'Ref: you pronoun': '(Sie)',
    'Ref: SR raised for': 'Betroffene Person',
    'Ref: Select a location': 'Wählen Sie einen Standort aus',
    'Ref: Your request': 'Ihre Anfrage',
    'Ref: Summary': 'Zusammenfassung',
    'Ref: site': 'Standort',
  },
  Comment: {
    'Ref: Page title': 'Aktualisierung',
    'Ref: please update us with details':
      'Haben Sie weitere Informationen? Ein Kommentar? Je mehr wir über Ihre Anfrage wissen, desto besser können wir Sie unterstützen.',
  },
  Confirm: {
    'Ref: Page title': 'Bestätigen Sie Ihre Anfrage',
    'Ref: next': 'Einreichen',
    summary: 'Zusammenfassung',
    'Ref: Save as my preferred location': 'Speichern Sie als mein bevorzugter Ort',
  },
  Describe: {
    'Ref: Page title': 'Beschreiben Sie Ihre Anfrage',
    'Ref: Required fields: header': 'Bitte geben Sie zusätzliche Informationen an',
    'Ref: Required fields': 'Die folgenden Informationen sollten im Detailfeld enthalten sein:',
    'Ref: Required field: title': 'Ein Titel ist erforderlich.',
    'Ref: Required field: description': 'Eine Beschreibung ist nicht erforderlich.',
    'Ref: Required field: phone': 'Eine Telefonnummer ist erforderlich.',
    'Ref: Required field: category': 'Eine Kategorie ist erforderlich.',
    'Ref: Invalid field: category': 'Die angegebene Kategorie ist ungültig.',
    'Ref: Request template field label': 'Beliebte Anfragen',
    'Ref: Request template field placeholder': 'Leeres Blatt',
    your_request: 'Ihre Anfrage',
    'Ref: Description message':
      'Je detaillierter Sie sind, desto besser können wir Sie unterstützen.',
    phone_number:
      'Ihre Telefonnummer (bitte verwenden Sie Ihr Diensttelefon, wenn Sie eines haben)',
    'Ref: Affected person': 'Betroffene Person',
    'Ref: No affected person results': 'Keine Person, die den gewünschten Kriterien entspricht',
    'Ref: Notification title': 'Sie haben den Standort geändert',
    'Ref: Notification info': 'Sie sind jetzt bei {site name}',
    'Ref: Add photos': 'Fotos hinzufügen',
    'Ref: Your location': 'Ihr Standort',
    'Ref: limit field: title': 'Der Titel sollte weniger als {ref} Zeichen umfassen',
    'Ref: limit field: description': 'Die Beschreibung sollte weniger als {ref} Zeichen umfassen',
  },
  Details: {
    'Ref: Page title': 'Anfragedetails',
    Details: 'Einzelheiten',
    Updates: 'Aktualisierung',
    'Update us': 'Informieren Sie uns',
    'Ref: timeline': 'Zeitraum',
    'Ref: attachments': 'Anhänge',
    'Ref: location': 'Standort',
    'Ref: createdOn': 'Erstellt am',
    'Ref: reference': 'Referenz',
    'Feedback was submitted': 'Feedback abgeschickt',
    'Ref: sent': 'Gesendet',
    'Ref: received': 'Erhalten',
    'Ref: inProgress': 'In Bearbeitung',
    'Ref: completed': 'Abgeschlossen',
    'Ref: conversation': 'Gespräch',
    'Ref: Priority': 'Priorität',
    'Ref: Target start date': 'Starttermin',
    'Ref: Target completion date': 'Geplantes Fertigstellungsdatum',
    'Ref: Priority fields placeholder message':
      'Die Daten werden nach der Überprüfung durch das Beratungsstelle ausgefüllt.',
  },
  Failed: {
    'Ref: Body':
      'Ein Fehler ist aufgetreten und Ihre Serviceanfrage wurde nicht erstellt. Wenn dieser Fehler weiterhin besteht, wenden Sie sich bitte an Ihren Beratungsstelle.',
    'Ref: Tabtitle': 'Die Serviceanforderung konnte nicht erstellt werden',
  },
  Home: { 'Ref: Page title': 'Anfragen' },
  RequestFeedback: {
    'Ref: Page title': 'Feedback',
    'Ref: Feedback headline': 'Bitte teilen Sie Ihr Feedback mit',
    'Ref: Feedback title': 'Feedback zu sr # {ref}',
    'Ref: Question: general sentiment':
      'Wie zufrieden sind Sie insgesamt mit der Art und Weise, wie Sodexo Ihre Serviceanfrage bearbeitet hat?',
    'Ref: Question: answer quality':
      'Wie würden Sie die Qualität der Dienstleistung bewerten Sie erhalten haben?',
    'Ref: Question: staff interaction':
      'Wie würden Sie den Umgang mit unseren Mitarbeitern bewerten?',
    'Ref: Question: efficiency': 'Wie würden Sie unsere Effizienz bewerten?',
    'Ref: Question: open': 'Kommentar',
    'Ref: Open question placeholder': 'Was haben wir gut gemacht? Wo können wir uns verbessern?',
    no_comment: 'Kein Kommentar',
  },
  FeedbackSuccess: {
    'Ref: Body':
      'Danke, dass Sie sich die Zeit genommen haben, einen Kommentar zu hinterlassen. Ihr Feedback ist wichtig für uns, um Ihre Erfahrungen zu verbessern.',
  },
  Locate: {
    'Ref: Page title': 'Wählen Sie einen Standort',
    'Ref: Top bar title': 'Suchen Sie Ihre Anfrage',
    'Ref: Select this location': 'Wählen Sie diesen Ort aus',
    drill: 'durchsuchen',
    'Ref: Scanner button': 'Standort mit einem QR? Scannen Sie diesen',
    'Ref: Scanner header': 'Bitte scannen Sie Ihren Standort QR',
    'Sorry, this location could not be found.':
      'Entschuldigung, dieser Standort konnte nicht gefunden werden.',
    'Close scanner': 'Scanner schließen',
    'Ref: Switch site component intro sentence':
      'Sie fordern eine Dienstleistung für diesen Standort an {site name}.',
    'Ref: search location by keywords': 'Standort suchen',
    'Ref: Enter Location name': 'Geben Sie den Standortnamen ein',
    'Ref: Enter manual location': 'Geben Sie den Standort manuell ein',
    'Ref: Location name': 'Standortname',
    'Ref: No results': 'Wir konnten Ihren Standort nicht finden. Geben Sie ihn bitte manuell ein.',
    'Ref: Up one level': 'Eine Ebene höher',
    'Ref: Navigate': 'Navigieren',
    'Ref: Select': 'Auswählen',
    'Ref: Selected Location': 'Ausgewählter Ort',
    'Ref: Preferred Location': 'Bevorzugter Ort',
    'Ref: Building': 'Gebäude',
    'Ref: Floor': 'Boden',
    'Ref: Aisle': 'Gang',
    'Ref: Room': 'Zimmer',
    'Ref: Notification title': 'Sie haben den Standort geändert',
    'Ref: Notification info': 'Sie sind jetzt bei {site name}',
    'Ref: Scanner error header': 'Scanfehler',
    'Ref: Scan QR Code': 'QR-Code scannen',
    'Ref: Error body': 'Dieser QR -Code ist kein gültiger Code',
    'Ref: Loading Locations Warning':
      'Bitte warten Sie, während wir Ihr Konto für eine erste Anfrage auf dieser Website vorbereiten.',
    'Ref: Search Result is limited to:':
      'Das Suchergebnis ist auf {limit} beschränkt. Bitte verfeinern Sie Ihre Suchkriterien.',
    'Ref: Open the location search field': 'Öffnen Sie das Feld "Standortsuche"',
  },
  Success: {
    'Ref: Body':
      'Wir haben Ihre Anfrage erhalten und prüfen sie gerade. Wir werden uns bald bei Ihnen melden. Es kann bis zu 20 Minuten dauern, bis Ihre Serviceanfrage in der App angezeigt wird.',
    'Ref: Tabtitle': 'Serviceanfrage erstellt',
    'Ref: Download app title': 'Vereinfachen Sie Ihre Serviceanfragen',
    'Ref: Download app subtitle': 'Laden Sie unsere App herunter und steigern Sie Ihr Erlebnis',
    'Ref: Download app button': 'Laden Sie die App herunter',
  },
  Tutorials: {
    'Ref: Tutorial: title': 'Hier finden Sie Unterstützung rund um Ihren Arbeitsplatz',
    'Ref: Tutorial: tabname': 'Anfragen',
    'Ref: Tutorial: body':
      'Wir sind hier, um Ihnen zu helfen. Bitte erfassen Sie eine Anfrage pro Eintrag.',
    "What's new: title": 'Einfache Suche nach Ihrem Standort',
    "What's new: body":
      'Scannen Sie einen QR-Code, um Ihren genauen Standort zu finden, oder wählen Sie aus unseren Vorschlägen.',
  },
  Widget: {
    'Ref: Service request': 'Serviceanfragen',
    'Ref: Placeholder body': 'Aktuell keine Serviceanfragen vorhanden',
    'Ref: New request button': 'Neue Anfrage',
  },
  QrLandingPage: {
    'Ref: Notification title': 'Sie haben den Standort geändert',
    'Ref: Notification info': 'Sie sind jetzt bei {site name}',
    'Ref: Welcome': 'Willkommen! {wave_emoji}',
    'Ref: Create service request text': 'Ist Ihnen etwas aufgefallen, das Sie melden möchten?',
    'Create a service request': 'Erstellen Sie eine Serviceanfrage',
    'Ref: Share feedback': 'Teilen Sie ein Feedback',
    'Ref: Share text': 'Möchten Sie etwas mit uns teilen?',
    'Go to home': 'Zur Startseite',
    'Ref: Error body':
      'Der von Ihnen gescannte QR-Code funktioniert nicht mehr. Bitte versuchen Sie es später noch einmal oder wenden Sie sich an den Support.',
  },
  QrLandingPageError: {
    'Ref: Error title': 'Ein Fehler ist aufgetreten',
    'Ref: Error body':
      'Der von Ihnen gescannte QR-Code funktioniert nicht mehr. Bitte versuchen Sie es später noch einmal oder wenden Sie sich an den Support.',
    'Go to home': 'Zur Startseite',
  },
  ManualDescribe: {
    'Ref: Page title': 'Beschreiben Sie Ihre Anfrage',
    'Ref: Required fields: header': 'Bitte geben Sie zusätzliche Informationen an',
    'Ref: Required fields':
      'Die folgenden Informationen sollten im Feld Details bereitgestellt werden:',
    'Ref: Required field: title': 'Eine Zusammenfassung ist erforderlich.',
    'Ref: Required field: description': 'Eine Beschreibung ist erforderlich.',
    'Ref: Required field: category': 'Eine Kategorie ist erforderlich.',
    'Ref: Required field: buildingText': 'Ein Gebäude ist erforderlich.',
    'Ref: Invalid field: category': 'Die Kategorie ist ungültig.',
    'Ref: Your request': 'Ihre Anfrage',
    'Ref: Description message':
      'Je detaillierter Sie sind, desto besser können wir Sie unterstützen.',
    'Ref: Do you have a location QR code': 'Haben Sie einen Standort QR -Code?',
    'Ref: Scan QR Code': 'QR-Code scannen',
    'Ref: Please select category': 'Bitte wählen Sie Kategorie',
    'Ref: Please select site': 'Bitte wählen Sie den Standort',
    'Ref: building': 'Gebäude',
    'Ref: room': 'Raum',
    'Ref: Scan error': 'Scanfehler',
    'Ref: The QR code is not valid': 'Der QR -Code ist nicht gültig',
    'Ref: Add location with QR Scanner': 'Fügen Sie den Standort mit dem QR-Scanner hinzu',
    'Ref: Description': 'Beschreibung',
    'Ref: Forbidden error':
      'Es tut uns leid, dieser QR -Code stimmt nicht mit Ihrem registrierten Standort überein.',
    'Ref: Invalid error':
      'Es tut uns leid, es scheint, dass dieser QR -Code nicht mehr gültig ist.',
  },
};
export default labels;
